body {
    /*    background-color: #fff !important; */
    overflow-x: hidden
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --white: #fff;
    --red: #d90e16;
    --grey: #343434;
    --black: #000;
    --blue: #3d66ab;
    --lightgrey: #525657;
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 10px;
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 10px;
}

.main {
    display: flex;
    flex-direction: column;
}

.ifshdrRow {
    width: 100%;
    z-index: 100000;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    background-color: #fff;
}

.ifshdrTop {
    background: #3d66ab;
    width: 100%;
    float: left;
}

.ifshdrTop>.wrapper {
    min-height: 37px;
    padding: 0 15px;

}

.ifshdrTopmain {
    text-align: right;
}

.ifshdrTopmain ul {
    padding: 0;
    margin: 0;
}

.ifshdrTopmain ul li {
    list-style: none;
    display: inline-block;
    margin: 0;
}

.ifshdrTopmain ul li a {
    display: block;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px !important;
    padding: 5px 15px;
}

.ifshdrTopmain ul li a {
    font-weight: 700 !important;
    font-family: 'Montserrat', sans-serif !important;
}

body .ifshdrTopmain ul li a {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif !important;
}

.ifshdrTopmain ul li a span svg {
    width: 20px;
    vertical-align: top;
    height: auto;
    margin: 2px 0 0 0;
}

.ifshdrTopmain ul li:last-child {
    position: relative;
    z-index: 3;
}

.ifshdrTopmain ul li:last-child a {
    padding-right: 0 !important;
}

.ifshdr {
    width: 100%;
    float: left;
}

.ifshdr .wrapper,
.custftr .wrapper {
    max-width: 1250px !important;
    padding: 0 15px;

}

.ifshdrmain {
    width: 100%;
    float: left;
}

.ifs_page_header .ifshdrmain {
    padding: 15px 0;
}

.ifsHdrRt {
    width: 60%;
    float: right;
}

.custhdr {
    display: none;
}

.ifsHdrRt .wrapper {
    padding: 0 !important;
}

.ifsHdrRt .custnav {
    width: 100% !important;
}

#cssmenu #menu-button {
    float: left !important;
}

.ifshdr #cssmenu .submenu-button {
    height: 67px;
    width: 66px;
}

.ifshdr #cssmenu ul li a {
    font-family: 'Montserrat', sans-serif !important;
    padding: 22px 15px;
    font-size: 21px;
}

body .ifshdr #cssmenu ul li a {
    font-family: 'Montserrat', sans-serif !important;
}

.ifshdr #cssmenu ul ul li a {
    font-size: 18px !important;
    padding: 10px 0 !important;
}

.ifsMenu {
    width: 100%;
    float: left;
    margin: 55px 0 0 0;
}

.ifs_page_header .ifsMenu {
    margin: 30px 0 30px 0;
}

.ifs_page_header .ifslogo img,
.ifs_page_header .ifsMenu,
.ifs_home_header .ifslogo img,
.ifs_home_header .ifsMenu {
    height: auto;
    transition: ease-in-out .3s;
}

.ifshdrRow .ifsHdrRt .ifsMenu {
    text-align: right;
}

.ifsMenu>ul {
    padding: 0;
    margin: 0;
    text-align: right;
    position: relative;
}

.ifsMenu>ul>li {
    display: inline-block;
    margin: 0 18px !important;
}

.ifsMenu>ul>li {
    margin: 0 15px !important;
}

.ifsMenu>ul>li>a {
    display: inline-block;
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif !important;
    text-transform: uppercase;
}

#tve_thrive_lightbox_36378 .thrv_text_element p,
.NewHmBanr ul li h4,
.areas_serviced_main .areas_serviced_content,
.custfollowusnew .cff-header-text,
.custfollowusnew .cff-header-text p,
.custfollowusnew .cff-post-text,
.ifsGetintouchForm .tcb-plain-text>.tcb-plain-text,
.ifsMenu>ul>li>a,
.ifschwcboxTxt p,
.ifschwcboxhead h3,
.page-id-83623 .ftrCol .rcbli_right_text a,
.search-results #content :not(#tve) .tcb-post-content.tcb-shortcode.thrv_wrapper p {
    font-family: 'Montserrat', sans-serif !important;
}

#tve_thrive_lightbox_36378 .thrv_text_element p,
.NewHmBanr ul li h4,
.areas_serviced_main .areas_serviced_content,
.custfollowusnew .cff-header-text,
.custfollowusnew .cff-header-text p,
.custfollowusnew .cff-post-text,
.ifsGetintouchForm .tcb-plain-text>.tcb-plain-text,
.ifsMenu>ul>li>a,
.ifschwcboxTxt p,
.ifschwcboxhead h3,
.page-id-83623 .ftrCol .rcbli_right_text a,
.search-results #content :not(#tve) .tcb-post-content.tcb-shortcode.thrv_wrapper p {
    font-family: 'Montserrat', sans-serif !important;
}

body .ifsMenu>ul>li>a,
body .ifssubmenu>li>a>span {
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 600;
}

.ifs_page_header .ifsMenu>ul>li>a {
    color: #d90e16 !important;
}

.ifssubmenu {
    position: absolute;
    width: 100%;
    top: 100%;
    right: 0;
    display: none;
    z-index: 99;
    min-width: 710px;
    padding-top: 0 !important;
    margin: 0;
    box-shadow: rgba(0, 0, 0, .5) 1px 1px 4px;
    background: #fff;
}

.ifssubmenu,
.job_item_content ol,
.job_item_content ul {
    padding-left: 0;
}

.ifsMenu>ul>li:last-child {
    margin-right: 0 !important;
}

.ifx_searchli .search-form {
    right: 200px;
    top: 200px;
    margin: 0 !important;
}

.ifx_searchli .search-form label {
    cursor: pointer;
    width: 26px;
    display: inline-block;
}

.ifx_searchli .search-form label::before {
    content: "\f002";
    font-family: FontAwesome;
    color: #d90e16 !important;
    font-size: 19px;
}

.ifx_searchli .search-field {
    background-color: transparent;
    border: 2px solid transparent;
    height: 40px;
    transition: width .4s, background .4s;
    width: 0;
    cursor: pointer;
    position: absolute !important;
    right: 0 !important;
    background-image: none !important;
    margin: 0 !important;
    padding: 0 10px !important;
    top: -3px;
    box-shadow: none !important;
}

.ifx_searchli .search-form .search-submit {
    background: #e6e6e6;
    border: 1px solid #e6e6e6;
    color: rgba(0, 0, 0, .8);
    font-size: 1rem;
    line-height: 20px;
    padding: 10px 20px;
    display: none;
}

.custstartYrBtn {
    display: none;
}

.custIfsloaction {
    float: right;
    display: none;
}

.ifsMenu>ul>li:hover .ifssubmenu {
    display: block;
}

.ifssubmenu>li {
    padding: 0;
    background: #fff;
    opacity: 0;
    transform-origin: bottom;
    -webkit-animation: enter .2s ease forwards;
    animation: enter .2s ease forwards;
    margin: 0 !important;
    list-style: none;
    float: left;
    width: 20%;
    border-bottom: 1px solid #fff;
}

@-webkit-keyframes enter {
    from {
        opacity: 0;
        transform: scaleY(0.98) translateY(10px)
    }

    to {
        opacity: 1;
        transform: none
    }
}

@keyframes enter {
    from {
        opacity: 0;
        transform: scaleY(0.98) translateY(10px)
    }

    to {
        opacity: 1;
        transform: none
    }
}

.ifssubmenu li:nth-child(1) {
    -webkit-animation-duration: .2s;
    animation-duration: .2s;
    -webkit-animation-delay: 0;
    animation-delay: 0
}

.ifssubmenu li:nth-child(2) {
    -webkit-animation-duration: .25s;
    animation-duration: .25s;
    -webkit-animation-delay: .1s;
    animation-delay: .1s
}

.ifssubmenu li:nth-child(3) {
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-animation-delay: .15s;
    animation-delay: .15s
}

.ifssubmenu li:nth-child(4) {
    -webkit-animation-duration: .35s;
    animation-duration: .35s;
    -webkit-animation-delay: .2s;
    animation-delay: .2s
}

.ifssubmenu li:nth-child(5) {
    -webkit-animation-duration: .4s;
    animation-duration: .4s;
    -webkit-animation-delay: .25s;
    animation-delay: .25s
}

.ifssubmenu li:nth-child(6) {
    -webkit-animation-duration: .45s;
    animation-duration: .45s;
    -webkit-animation-delay: .3s;
    animation-delay: .3s
}

.ifssubmenu li:nth-child(7) {
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    -webkit-animation-delay: .35s;
    animation-delay: .35s
}

.ifssubmenu li:nth-child(8) {
    -webkit-animation-duration: .55s;
    animation-duration: .55s;
    -webkit-animation-delay: .4s;
    animation-delay: .4s
}

.ifssubmenu li:nth-child(9) {
    -webkit-animation-duration: .6s;
    animation-duration: .6s;
    -webkit-animation-delay: .45s;
    animation-delay: .45s
}

.ifssubmenu li:nth-child(10) {
    -webkit-animation-duration: .65s;
    animation-duration: .65s;
    -webkit-animation-delay: .5s;
    animation-delay: .5s
}

.ifssubmenu li:nth-child(11) {
    -webkit-animation-duration: .7s;
    animation-duration: .7s;
    -webkit-animation-delay: .55s;
    animation-delay: .55s
}

.ifssubmenu>li>a {
    display: inline-block;
    padding: 0;
    font-size: 13px;
    width: 100%;
    text-align: center;
    text-decoration: none;
    color: #000;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif !important;
}

.ifssubmenu>li>a>svg {
    height: 60px;
    padding: 5px 10px 10px 0;
    margin-top: 20px;
    width: auto;
}

.ifssubmenu>li>a>span {
    display: inline-block;
    padding: 14px 0;
    background: #f8f8f8;
    width: 100%;
    line-height: normal;
    font-family: 'Montserrat', sans-serif !important;
    text-transform: none !important;
    font-size: 13px;
    font-weight: 600 !important;
}

.errfield {
    display: none;
}

.prtnrFrm {
    display: inline-block;
    width: 100%;
}

.errfieldshw {
    display: block;
}

.loading {
    height: 0;
    width: 0;
    padding: 15px;
    border: 6px solid #ccc;
    border-right-color: #888;
    border-radius: 22px;
    animation: rotate 1s infinite linear;
    -webkit-animation: rotate 1s infinite linear;
    /* left, top and position just for the demo! */
    position: absolute;
    left: 50%;
    top: 50%;
}

@keyframes rotate {

    /* 100% keyframe for  clockwise. 
use 0% instead for anticlockwise */
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes rotate {

    /* 100% keyframe for  clockwise. 
use 0% instead for anticlockwise */
    100% {
        -webkit-transform: rotate(360deg);
    }
}

.loading_wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #00000050;
    top: 0;
    left: 0;
    z-index: 100000000;
}

.custftr {
    width: 100%;
    float: left;
}

.custftr {
    position: relative;
    z-index: 2;
}

.custftrSec1 {
    width: 100%;
    float: left;
    background: #525657;
    padding: 50px 0;
    z-index: 2;
    position: relative;
}

.ifsc_footer .custftrSec1 {
    padding: 100px 0 50px !important;
    background-color: #212121 !important;
}

.ifsc_footer .custftrSec1>.wrapper {
    display: grid !important;
    grid-template-columns: 2fr 1fr 1fr 2fr !important;
}

.ftrCol {
    width: 22.7%;
    float: left;
    margin: 0 3% 0 0;
}

.ifsc_footer .custftrSec1>.wrapper>.ftrCol {
    width: 100% !important;
    margin: 0 !important;
    padding-right: 10px;
}

.ftrCol .ttl {
    width: 100%;
    float: left;
    margin-bottom: 20px !important;
    color: #e6e6e6;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
}

.ifsc_footer .ttl {
    position: relative;
    padding-bottom: 20px !important;
    font-size: 21px !important;
    line-height: 25px !important;
    text-transform: capitalize !important;
    font-family: 'Montserrat', sans-serif !important;
}

.ifsc_footer .ttl {
    font-family: 'Montserrat', sans-serif !important;
}

.ifsc_footer .ttl::after {
    content: '';
    position: absolute;
    width: 10%;
    height: 5px;
    left: 0;
    bottom: 0;
    background: var(--red);
}

.textwidget {
    width: 100%;
    float: left;
}

#subscribeForm {
    width: 80%;
}

.ifschowwecanRow.newlist,
#subscribeForm .inpcol {
    display: block !important;
}

#subscribeForm .isRequired {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -ms-box-shadow: none !important;
    -o-box-shadow: none !important;
    -webkit-box-shadow: none !important;
}

#subscribeForm .isRequired {
    width: 100%;
}

.errfield {
    font-size: 13px;
    color: red;
    line-height: normal;
    margin: 0;
}

.errfield {
    display: none;
}

#subscribeForm .errfield {
    width: 100%;
}

#subscribeForm .tve-froala {
    cursor: pointer;
    outline: 0 !important;
    margin: 10px 0;
}

.ifsc_footer .tve-froala {
    transition: all .35s;
    width: 100%;
    padding: 12px 20px !important;
    font-weight: 700 !important;
    text-transform: uppercase !important;
    font-size: 14px !important;
    border-radius: 0 !important;
    text-decoration: none !important;
    border: 2px solid #d90e16 !important;
    background: #d90e16 !important;
    color: #fff !important;
    font-family: 'Montserrat', sans-serif !important;
}

/* aasdadada */







* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.steps_list_main_wrap {
    display: flex;
}

.steps_list_main {
    min-width: 100%;
}

.steps_list_item_hdr .h1 {
    font-size: 40px;
    line-height: 50px;
    color: #3e67ac;
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    margin: 0 0 10px;
    text-align: center;
}
.steps_list_item_hdr .h3 a {
    color: inherit;
}
.steps_list_item_hdr .h3 {
    font-size: 22px;
    line-height: 35px;
    color: #3e67ac;
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    margin: 0;
    margin: 0 0 10px 0;
    text-align: center;
}

.steps_list_item_hdr .text {
    margin: 5px 0 10px 0;
    color: #3e67ac;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
}

.dont_see {
    margin: 5px 0 10px 0;
    color: #333;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
}

.dont_see a {
    text-decoration: underline;
    display: block;
    cursor: pointer;
    color: inherit;
}

.devices_grid {
    margin: 20px 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
}

.devices_grid_item * {
    pointer-events: none;
}

.devices_grid_item {
    width: calc(20% - 120px/5);
    border: 5px solid #ebebeb;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, .1);
    cursor: pointer;
    background-color: #fff;
    padding: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    gap: 20px;
}

.devices_grid_item.active::before {
    opacity: 0.5;
    pointer-events: all;
}

.devices_grid_item::before {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    pointer-events: none;
    height: 100%;
    background-color: #3e67ac;

}

.devices_grid_item .image_wrap {
    width: 110px;
    height: 110px;
    margin: 0 auto;
    display: flex;
}

.devices_grid_item .image_wrap svg {
    width: 100%;
    height: 100%;
}

.devices_grid_item .text {
    line-height: 1.1;
    font-weight: 600;
    color: #000;
    margin: 25px 0;
    white-space: nowrap;
    font-family: 'Montserrat', sans-serif !important;
    font-size: 18px;
}

.second .steps_list_item_hdr .h1 {
    text-align: left;
}

.second .steps_list_item_hdr .h3 {
    text-align: left;
}

.search_form .input_wrap {
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 50px;
    padding: 10px;
    position: relative;
    display: flex;
    gap: 10px;
}

.search_form .input_wrap .icon {
    min-width: 20px;
    height: 20px;
    display: flex;
}

.search_form .input_wrap .icon svg {
    width: 20px;
    height: 20px;
}

.search_form .input_wrap .text::-webkit-input-placeholder {
    opacity: 1;
}

.search_form .input_wrap .text::-moz-placeholder {
    opacity: 1;
}

.search_form .input_wrap .text:-ms-input-placeholder {
    opacity: 1;
}

.search_form .input_wrap .text::-ms-input-placeholder {
    opacity: 1;
}

.search_form .input_wrap .text::placeholder {
    opacity: 1;
}

.search_form .input_wrap .text {
    font-family: 'Montserrat';
    outline: none;
    background-color: transparent;
    border: none;
    padding-left: 40px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-right: 15px;
}

.or_divider {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    gap: 20px;
    color: rgba(0, 0, 0, .6);
    font-family: 'Montserrat';
}

.or_divider::before,
.or_divider::after {
    content: '';
    display: flex;
    width: 100%;
    height: 2px;
    background-color: rgba(0, 0, 0, .1);
}

.use_location_toggle {
    display: flex;
    align-items: center;
    font-family: 'Montserrat';
    justify-content: center;
    gap: 10px;
    color: rgba(0, 0, 0, .6);
}

.use_location_toggle .icon {
    width: 20px;
    height: 20px;
}

.use_location_toggle .icon svg {
    width: 20px;
    height: 20px;
}

.steps_list_item_row {
    display: flex;
    gap: 50px;
}

.steps_list_item_row .left_half {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    width: 100%;
    padding: 50px 0 0;
}

.steps_list_item_row .fixed_side_half {
    min-width: 400px;
    padding: 30px 50px;
    border-left: 1px solid rgba(0, 0, 0, .1);
}

.steps_list_item.first {
    padding: 50px 0;
}

.fixed_steps_list::before {
    content: '';
    width: 100%;
    height: 2px;
    background-color: #ddd;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
}


.fixed_steps_list {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 25px;
}

.fixed_steps_list_item {
    position: relative;
    z-index: 1;
}

.issues_grid_item.active .image_wrap::before {
    opacity: 0.5;
    pointer-events: all;
}

.issues_grid_item .image_wrap::before {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    pointer-events: none;
    height: 100%;
    z-index: 1;
    background-color: #3e67ac;
}

.fixed_steps_list .circle {
    outline: 2px solid #3e67ac;
    display: flex;
    border-radius: 50%;
    min-width: 35px;
    height: 35px;
    cursor: pointer;
    background-color: #ddd;
}

.fixed_steps_list .active .circle {
    outline-color: #ddd;
    background-color: #3e67ac;
}

.summary_wrap {
    position: relative;
    padding-bottom: 40px;
    border-bottom: 2px solid #ddd;
}

.pricing_details {
    position: relative;
    padding-bottom: 40px;
    padding-top: 20px;
    border-bottom: 2px solid #ddd;
}

.need_to_schedule {
    position: relative;
    padding-bottom: 40px;
    padding-top: 40px;
}

.summary_wrap>.heading,
.visit_details>.heading,
.about_our_repairs>.heading,
.pricing_details>.heading,
.need_to_schedule>.heading {
    color: #3e67ac;
    font-family: 'Montserrat';
    font-weight: 600;
    margin-bottom: 20px;
}

.summary_wrap .summary_wrap_row {
    display: flex;
    justify-content: space-between;
}


.summary_wrap .device_selected>.image_wrap svg {
    width: 100%;
    height: 100%;
}

.summary_wrap .device_selected>.text {
    text-align: center;
    font-family: 'Montserrat';
    font-size: 14px;
}



.summary_wrap .device_other_details .selected_model,
.summary_wrap .device_other_details .selected_model_issue {
    color: #3338;
    font-size: 13px;
    font-family: 'Montserrat';
    font-weight: 600;
}


.step_navigation {
    display: flex;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    padding: 30px 50px;
    background-color: #fff;
    align-items: center;
    gap: 20px;
    width: calc(100% + 50px);
    justify-content: flex-end;
    border-top: 1px solid #3332;
}

.step_navigation {
    margin-top: auto;
}

.step_navigation_button {
    outline: 1px solid var(--blue);
    border-radius: 50px;
    color: var(--blue);
    font-family: 'Montserrat';
    font-weight: normal;
    display: inline-flex;
    padding: 10px 30px;
    cursor: pointer;
}

.step_navigation_button.continue,
.step_navigation_button:hover {
    outline-color: #3e67ac;
    background-color: #3e67ac;
    color: #fff;
}

.checkbox_list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.checkbox_list_item {
    /* margin-bottom: 15px; */
}

.checkbox_list_item input[type="checkbox"] {
    display: none;
}

.checkbox_list:not(.visit_details_checks) .checkbox_list_item input[type="checkbox"]:checked~label::after {
    opacity: 1;
} 
.checkbox_list:not(.visit_details_checks) .checkbox_list_item label::after {
    opacity: 0;
    content: '';
    width: 5px;
    position: absolute;
    height: 13px;
    border: solid #333;
    border-width: 0px 2px 2px 0px;
    transform: rotate(45deg) translateY(-50%) translateX(-12px);
    left: 10px;
    top: calc(26px/2);
}

.about_our_repairs .checkbox_list:not(.visit_details_checks) .checkbox_list_item label::after {
    opacity: 1;
}

.checkbox_list_item label {
    padding-left: 30px;
    display: flex;
    min-height: 19px;
    position: relative;
    color: #3337;
    font-family: 'Montserrat';
    font-weight: 600;
}

.visit_details {
    padding: 40px 0;
    border-bottom: 2px solid #ddd;
}

.about_our_repairs {
    padding: 40px 0;
}

.issues_grid {
    display: flex;
    flex-wrap: wrap;
    gap: 0px 20px;
    margin-top: 30px;
}

.issues_grid_item {
    cursor: pointer;
    width: calc(20% - 80px/5);
}

.issues_grid_item .image_wrap {
    position: relative;
    width: 100%;
    outline: 5px solid #ededed;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, .1);
}

.issues_grid_item .text {
    width: 100%;
    line-height: 1.1;
    font-weight: 600;
    color: #000;
    margin: 20px 0 20px;
    font-family: 'Montserrat', sans-serif !important;
    font-size: 18px;
    text-align: center;
}

.issues_grid_item .image_wrap img {
    display: flex;
    width: 100%;
    padding: 17%;
}

.optional_issues textarea {
    font-family: 'Montserrat';
    padding: 20px;
    outline: none;
    border: 2px solid #ddd;
    border-radius: 30px;
    min-height: 150px;
    resize: none;
    width: 100%;
}

.textarea_characters_limit {
    font-family: 'Montserrat';
    color: #3338;
    text-align: right;
    margin-top: 10px;
}

.nearest_locations {
    font-size: 17px;
    color: #333;
    font-family: 'Montserrat';
}

.nearest_locations .change_location {
    cursor: pointer;
    text-decoration: underline;
    display: inline-flex;
}

.convenience_radio_list {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 25px;
}

.convenience_radio_list_item.active {
    border-color: #3e67ac;
}

.convenience_radio_list_item {
    border-radius: 5px;
    width: calc(33.3% - 10px/3);
    padding: 10px 20px 10px 10px;
    border: 2px solid #ddd;
    display: inline-flex;
    align-items: center;
    color: #3337;
    font-size: 12px;
    font-family: 'Montserrat';
    gap: 7px;
}

.convenience_radio_list_item::before {
    content: '';
    display: inline-flex;
    min-width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid #ddd;
    background-color: transparent;
}

.convenience_radio_list_item.active::before {
    background-color: #3e67ac;
}

.stores_near_you {
    margin-top: 25px;
    padding: 20px 0;
    border-top: 2px solid #ddd;
}

.stores_near_you_number {
    margin-bottom: 20px;
    font-family: 'Montserrat';
    color: #333;
}

.map_wrap {
    width: 100%;
}

.map_wrap iframe {
    width: 100%;
    border: none;
    height: 175px;
}

.near_stores_list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.near_stores_list_item::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #ddd8;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: rotate(90deg) translateY(-50%) translateX(-8px);
}

.near_stores_list_item.active::before {
    background-color: #3e67ac;
    outline: #3e67ac;
}

.near_stores_list_item::before {
    content: '';
    position: absolute;
    --size: 25px;
    width: var(--size);
    height: var(--size);
    outline: 2px solid #ddd;
    border-radius: var(--size);
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
}

.near_stores_list_item {
    cursor: pointer;
    position: relative;
    border: 2px solid #ddd;
    padding: 20px;
    padding-left: 45px;
    border-radius: 20px;
    font-family: 'Montserrat';
}

.near_stores_list_item .store_name {
    font-size: 20px;
    color: #3338;
    font-weight: 600;
    display: inline-flex;
    margin-right: 15px;
}

.near_stores_list_item .store_distance {
    display: inline-flex;
    display: none;
    font-size: 12px;
    color: #3338;
    font-weight: 400;
}

.near_stores_list_item .store_address {
    font-size: 14px;
    color: #3338;
    font-weight: 600;
    margin-bottom: 5px;
}

.near_stores_list_item .next_date_available {
    font-size: 14px;
    color: #3338;
    font-weight: 600;
}

.near_stores_list_item .next_date_available b {
    color: #3e67ac;
}

.store_selected {
    font-family: 'Montserrat';
    color: #3338;
    font-size: 12px;
    margin-bottom: 5px;
}

.choose_a_date,
.choose_time {
    padding: 10px;
    margin-bottom: 10px;
}

.choose_a_date h5,
.choose_time h5 {
    font-family: 'Montserrat';
    margin-bottom: 10px;
}

.dates_list {
    flex-wrap: wrap;
    display: flex;
    gap: 5px;
}

.dates_list_item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 2px solid #3332;
    padding: 15px;
    min-width: 93px;
    min-height: 90px;
    overflow: hidden;
    cursor: pointer;
}

.dates_list_item.closed::before {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    content: '';
    opacity: 0.5;
    background-color: #ddd;
}

.dates_list_item.closed .today {
    background-color: #ddd;
    color: #000;
    z-index: 10;
}

.dates_list_item.closed {
    pointer-events: none;
}

.dates_list_item .today {
    background-color: #f00;
    color: #fff;
    font-family: 'Montserrat';
    font-size: 11px;
    border-radius: 4px;
    padding: 2px 5px;
    margin-top: -18px;
}

.dates_list_item:hover,
.dates_list_item.active {
    border-color: #000;
}

.dates_list_item:hover *,
.dates_list_item.active * {
    pointer-events: none;
    position: relative;
    z-index: 1;
}

.dates_list_item:hover:before,
.dates_list_item.active::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #3e67ac;
    opacity: 0.5;
}

.dates_list_item .day {
    font-weight: 600;
    font-size: 14px;
    font-family: 'Montserrat';
}

.dates_list_item .date {
    font-weight: 500;
    font-size: 12px;
    font-family: 'Montserrat';
}

.time_list {
    display: flex;
    flex-wrap: wrap;
    gap: 5px 10px;
}

.time_list_item.active * {
    position: relative;
    z-index: 1;
    pointer-events: none;
}

.time_list_item:hover:before,
.time_list_item.active::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #3e67ac;
    top: 0;
    left: 0;
    opacity: 0.5;
}

.time_list_item {
    cursor: pointer;
    width: calc(20% - 40px/5);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 2px solid #3332;
    padding: 15px;
    overflow: hidden;
    font-weight: 500;
    font-size: 12px;
    font-family: 'Montserrat';
}

.contact_form {
    margin-bottom: 15px;
}

.contact_form .input_row {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.contact_form .input_column {
    width: 100%;
}

.contact_form .input::-webkit-input-placeholder {
    opacity: 1;
    color: #33333390;
    font-weight: 600;

}

.contact_form .input::-moz-placeholder {
    opacity: 1;
    color: #33333390;
    font-weight: 600;

}

.contact_form .input:-ms-input-placeholder {
    opacity: 1;
    color: #33333390;
    font-weight: 600;

}

.contact_form .input::-ms-input-placeholder {
    opacity: 1;
    color: #33333390;
    font-weight: 600;

}

.contact_form .input::placeholder {
    opacity: 1;
    color: #33333390;
    font-weight: 600;

}

.contact_form .help_text {
    font-family: 'Montserrat';
    font-size: 12px;
    color: #33333389;
    font-weight: 600;
    padding: 4px 12px;
}

.contact_form .input {
    outline: none;
    width: 100%;
    border: 1px solid #3337;
    height: 60px;
    border-radius: 10px;
    padding: 10px;
    font-family: 'Montserrat';
    color: #333;
}

.agree_row p {
    color: #333;
    font-family: 'Montserrat';
    font-weight: 600;
}

.agree_row p a {
    color: inherit;
}

.contact_form .checkbox_list {
    margin-top: 20px;
    margin-bottom: 10px;
}

.contact_form .checkbox_list_item label {
    color: #333;
    font-family: 'Montserrat';
    font-weight: 600;
}

.succes_banner .image_wrap {
    margin-bottom: 15px;
}

.succes_banner .image_wrap img {
    width: 100%;
    display: flex;
}

.action_button_list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.action_button_list .action_button {
    width: calc(33.3% - 20px/3);
    border: 2px solid #3e67ac;
    height: 50px;
    font-weight: 600;
    color: #3e67ac;
    font-size: 14px;
    font-family: 'Montserrat';
    border-radius: 50px;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    text-decoration: none;

}

.action_button_list .action_button .icon {
    display: flex;
    --size: 25px;
    width: var(--size);
    height: var(--size);
}

.action_button_list .action_button .icon svg {
    width: 100%;
    height: 100%;
}

.action_button_list .action_button.active {
    background-color: #3e67ac;
    color: #fff;
}

.about_our_repairs_sec {
    padding: 40px 0;
}

.about_our_repairs_sec>.heading {
    font-family: 'Montserrat';
    font-weight: 600;
    color: #000;
    font-size: 20px;
    margin-bottom: 20px;
}

.about_our_repairs_cards {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 50px;
}

.about_our_repairs_card {
    width: calc(33.3% - 100px/3);
}

.about_our_repairs_card .icon {
    --size: 70px;
    width: var(--size);
    height: var(--size);
    background-color: #eee;
    border-radius: var(--size);
    margin-bottom: 10px;
}

.about_our_repairs_card .icon svg {
    width: 100%;
    height: 100%;
    padding: 5px;
}

.about_our_repairs_card .name {
    font-family: 'Montserrat';
    color: #000;
    font-weight: 600;
    font-size: 17px;
}

.about_our_repairs_card .desc {
    font-family: 'Montserrat';
    color: #000;
    font-weight: 400;
    font-size: 14px;
}

.pricing_details .pricing {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
}

.pricing_details .pricing .label {
    padding: 10px 20px;
    font-family: 'Montserrat';
    color: #33333399;
    font-weight: 700;
}

.ifsc_footer .ttl::after {
    content: '';
    position: absolute;
    width: 10%;
    height: 5px;
    left: 0;
    bottom: 0;
    background: var(--red);
}

.pricing_details .pricing .label .help_text {
    font-size: 10px;
    line-height: 1.6;
    color: #3337;
    font-weight: 600;
    margin-top: 20px;
}

.pricing_details .pricing .value {
    padding: 10px 20px;
    white-space: nowrap;
    font-family: 'Montserrat';
    color: #33333399;
    font-weight: 700;
}

.need_to_schedule p {
    color: #3337;
    font-family: 'Montserrat';
    font-weight: 600;
}

.step_navigation_button.continue.disabled {
    background: #000;
    opacity: 0.5;
    cursor: not-allowed;
}

#subscribeForm .isRequired {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -ms-box-shadow: none !important;
    -o-box-shadow: none !important;
    -webkit-box-shadow: none !important;
}

#subscribeForm .isRequired {
    height: auto !important;
    font-size: 14px !important;
    padding: 12px !important;
    line-height: normal !important;
    box-shadow: none !important;
    margin: 10px 0;
}

.ftrCol .menu li {
    list-style: none;
    width: 100%;
    margin: 0;
    border-bottom: 1px dotted #242424;
}

.ftrCol .menu li {
    color: rgba(10, 10, 10, .85);
    font-family: Arial, sans-serif;
    font-size: 1rem;
    line-height: 1.75;
    letter-spacing: normal;
    font-weight: 400;
    margin: 5px 0 !important;
}

.ftrCol .menu {
    padding: 0;
    margin: 0;
    width: 100%;
    float: left;
}

.ftrCol .menu li a {
    color: #fff;
    font-size: 14px;
    line-height: 18px;
    padding: 8px 10px 8px 25px;
    position: relative;
    display: inline-block;
    width: 100%;
}

.ifsc_footer .ftrCol .menu li a {
    text-decoration: none;
    padding: 0 !important;
    font-family: 'Montserrat', sans-serif !important;
}

.ifsc_footer .ftrCol .menu li a,
.ifsc_rcbli_text a,
.ifsc_footer .custftrBtmLft p,
.ifsc_footer .ftrCol .textwidget p {
    font-family: 'Montserrat', sans-serif !important;
}

.ftrCol .menu li a::after {
    left: 5px;
    right: auto;
    font-size: 17px;
    top: 6px;
    color: #fff;
    content: '\000BB';
    font-family: "FontAwesome";
    position: absolute;
}

.ifsc_footer .ftrCol .menu li a::after {
    display: none !important;
}

.ftrCol .menu li a:hover {
    background: #242424;
    color: #e6e6e6;
    text-decoration: none;
}

.ftrCol .menu li a:hover {
    background: 0 0 !important;
    color: var(--red) !important;
}

.recent_blog_post {
    width: 100%;
    float: left;
}

.ifsc_rcbli {
    width: 100%;
    float: left;
    line-height: normal;
    margin-bottom: 10px;
}

.ifsc_rcbli_text {
    width: 100%;
    float: left;
}

.ifsc_rcbli_text a {
    display: inline-block;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif !important;
    color: #fff;
    font-size: 14px;
    line-height: 22px;
    position: relative;
}

.ifsc_rcbli_text a:hover {
    color: var(--red) !important;
    text-decoration: none !important;
}

.custftrBtm {
    width: 100%;
    float: left;
    background: #242424;
    padding: 25px 0 35px !important;
}

.ifsc_footer .custftrBtm {
    padding: 25px 0 !important;
    border-top: 1px dashed #383838;
    background: #212121;
}

.custftrBtmLft {
    float: left;
    width: 70%;
    color: #8c8989;
    font-size: 12px;
}

.custftrBtmLft p {
    font-size: 13px !important;
    line-height: 18px !important;
    color: #fff !important;
    margin: 0 0 10px !important;
    padding: 0 !important;
}

.ifsc_footer .custftrBtmLft p,
.ifsc_footer .ftrCol .textwidget p {
    font-size: 14px !important;
    line-height: 1.8 !important;
    font-family: 'Montserrat', sans-serif !important;
}

.ifsc_footer .ftrCol .menu li a,
.ifsc_rcbli_text a,
.ifsc_footer .custftrBtmLft p,
.ifsc_footer .ftrCol .textwidget p {
    font-family: 'Montserrat', sans-serif !important;
}

.custftrBtmRt ul {
    float: right;
    margin: 30px 0 0;
    padding: 0;
}

.custftrBtmRt ul li {
    margin: 0 0 0 15px;
    margin-left: 1.5em;
    display: inline-block;
}

.custftrBtmRt ul li a {
    color: #fff;
}

.cs-main,
.custftrBtmLft a {
    color: #fff;
    text-decoration: none;
}

.ftrCol .textwidget p {
    font-size: 14px;
    color: #fff;
    width: 100%;
    float: left;
    padding: 0;
}

.ifsc_footer .custftrBtmLft p,
.ifsc_footer .ftrCol .textwidget p {
    font-size: 14px !important;
    line-height: 1.8 !important;
    font-family: 'Montserrat', sans-serif !important;
}

.ifsc_footer .ftrCol .menu li a,
.ifsc_rcbli_text a,
.ifsc_footer .custftrBtmLft p,
.ifsc_footer .ftrCol .textwidget p {
    font-family: 'Montserrat', sans-serif !important;
}

.ftrCol .textwidget p a {
    color: #fff !important;
    text-decoration: none !important;
}

.ftrCol .textwidget p a:hover,
.ftrCol .textwidget p a:focus {
    color: var(--red) !important;
    text-decoration: none !important;
}

#subscribeForm .tve-froala:hover,
#subscribeForm .tve-froala:focus {
    background: #fff !important;
    border: 2px solid var(--red) !important;
    color: var(--red) !important;
    text-decoration: none !important;
}

.custIfsloaction a {
    color: #3d66ab;
    display: inline-block;
    margin: 10px 0 0 0;
}

.custIfsloaction a svg {
    width: 24px;
}

.ifslogo {
    width: 40%;
    float: left;
    background: #fff;
    position: relative;
    z-index: 9;
    margin: 0 0 -2px 0;
    padding: 0 0 2px 0;
}

.ifs_page_header .ifslogo {
    padding: 0;
    margin: 0;
    background: 0;
}

.ifshdr .wrapper,
.custftr .wrapper {
    max-width: 1250px !important;
}

.ifsHdrRt .wrapper {
    padding: 0 !important;
}

.ifshdr #cssmenu #menu-button.menu-opened {
    left: 10px !important;
    right: auto !important;
}

.navmenubtnstart {
    margin: 60px 0 0 !important;
    text-align: center;
}

.navmenubtnstart a {
    padding: 26px 60px !important;
    background: #3e67ac !important;
    font-weight: 600 !important;
}

.navmenubtnstart a {
    border-radius: 50px !important;
    font-size: 20px !important;
    color: #fff;
    width: auto !important;
    display: inline-block !important;
}

.navmenubtnstart a:focus,
.navmenubtnstart a:hover {
    background: #000 !important;
    color: #fff !important;
}

.ifslogo a img,
.ifslogo a svg {
    width: 350px;
    position: relative;
    bottom: -30px;
}

.ifs_page_header .ifslogo img {
    bottom: 0;
    width: 350px;
}

.ifslogo a {
    display: inline-block;
    padding: 0 5px 14px 0;
    margin: 0 0 -33px 0;
    position: relative;
    z-index: 2;
}

.ifs_page_header .ifslogo a {
    padding: 0;
}

@media only screen and (min-width: 100px) and (max-width: 767px) {
    body .navmenubtnstart a {
        font-size: 16px !important;
    }
}

@media all and (max-width:1199px),
only screen and (-webkit-min-device-pixel-ratio:2) and (max-width:1199px),
only screen and (min-device-pixel-ratio:2) and (max-width:1199px),
only screen and (min-resolution:192dpi) and (max-width:1199px),
only screen and (min-resolution:2dppx) and (max-width:1199px) {
    .ifshdr {
        box-shadow: rgba(0, 0, 0, .3) 0 0 3px;
        -moz-box-shadow: rgba(0, 0, 0, .3) 0 0 3px;
        -ms-box-shadow: rgba(0, 0, 0, .3) 0 0 3px;
        -o-box-shadow: rgba(0, 0, 0, .3) 0 0 3px;
        -webkit-box-shadow: rgba(0, 0, 0, .3) 0 0 3px
    }

    #cssmenu {
        width: 100%
    }

    #cssmenu #menu-button {
        background: #3d66ab;
        border: 0;
        display: inline-block !important;
        top: 8px !important;
        position: relative;
        right: 0;
        width: 40px !important;
        height: 40px !important;
        background-image: url(https://ifixscreens.com/storage/2020/12/menuIcon.png) !important;
        background-size: 22px !important;
        background-repeat: no-repeat !important;
        background-position: center !important;
        font-size: 0;
        cursor: pointer;
        z-index: 9999
    }

    .custmenu.isactive #cssmenu #menu-button {
        width: 30px !important;
        height: 30px !important;
        background: none !important
    }

    #cssmenu #menu-button::before {
        position: absolute;
        right: 0;
        left: 0;
        display: block;
        height: 2px;
        width: 20px;
        background: #fff;
        content: '';
        opacity: 0
    }

    #cssmenu #menu-button.menu-opened::before {
        top: 18px;
        background: #fff;
        width: 30px;
        transform: rotate(-45deg)
    }

    #cssmenu #menu-button::after {
        position: absolute;
        display: block;
        content: '';
        right: 0
    }

    #cssmenu #menu-button.menu-opened::after {
        top: 18px;
        border: 0;
        height: 2px;
        width: 30px;
        background: #fff;
        transform: rotate(45deg)
    }

    #cssmenu #menu-button.menu-opened::before,
    #cssmenu #menu-button.menu-opened::after {
        opacity: 1
    }

    #cssmenu #menu-button.menu-opened {
        position: fixed;
        background-image: none;
        top: 5px !important;
        right: 10px !important
    }

    #cssmenu .submenu-button {
        position: absolute;
        z-index: 99;
        right: 0;
        top: 0;
        display: block;
        height: 42px;
        width: 46px;
        cursor: pointer
    }

    #cssmenu .submenu-button::before {
        position: absolute;
        top: 19px;
        right: 22px;
        display: block;
        width: 2px;
        height: 8px;
        background: #fff;
        content: ''
    }

    #cssmenu .submenu-button::after {
        position: absolute;
        top: 22px;
        right: 19px;
        width: 8px;
        height: 2px;
        display: block;
        background: #fff;
        content: ''
    }

    #cssmenu .submenu-button.submenu-opened {
        background: #d90e16
    }

    #cssmenu .submenu-button.submenu-opened::after {
        background: #fff
    }

    .ifshdr #cssmenu ul li:hover .submenu-button:after,
    .ifshdr #cssmenu ul li:focus .submenu-button:after {
        background: var(--red) !important
    }

    .ifshdr #cssmenu ul li:hover .submenu-button:before,
    .ifshdr #cssmenu ul li:focus .submenu-button:before {
        background: var(--red) !important
    }

    #cssmenu .submenu-button.submenu-opened:focus::after {
        background: #d90e16 !important
    }

    .ifshdr #cssmenu ul li:hover .submenu-button.submenu-opened:before,
    .ifshdr #cssmenu ul li .submenu-button.submenu-opened:before {
        background: #fff !important
    }

    .ifshdr #cssmenu ul li:hover .submenu-button.submenu-opened:after {
        background: #fff !important
    }

    #cssmenu>ul>li.has-sub>a::after {
        display: none
    }

    #cssmenu:after,
    #cssmenu>ul:after {
        content: ".";
        display: block;
        clear: both;
        visibility: hidden;
        line-height: 0;
        height: 0
    }

    #cssmenu ul ul {
        top: 0;
        margin: 0;
        background: #fff;
        max-width: 100%;
        width: 100%;
        z-index: 3;
        visibility: visible;
        position: relative;
        left: 0
    }

    .custmenu #cssmenu ul li {
        width: 100%
    }

    #cssmenu ul ul li a::before {
        display: none
    }

    #cssmenu>ul>li {
        float: left;
        width: 100%;
        margin: 0;
        font-family: 'Montserrat', sans-serif !important;
    }

    #cssmenu>ul>li>a {
        padding: 12px 0;
        font-size: 18px;
        letter-spacing: 1px;
        text-decoration: none;
        color: #fff;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif !important;
    }

    #cssmenu>ul>li:hover>a {
        background: #fff;
        color: var(--red)
    }

    #cssmenu>ul>li:hover>a {
        background: #fff;
        color: var(--red)
    }

    #cssmenu ul ul ul {
        margin-left: 100%;
        top: 0
    }

    #cssmenu ul ul li a {
        border-bottom: 1px solid rgba(150, 150, 150, 0.15);
        padding: 11px 15px;
        width: 170px;
        font-size: 14px;
        text-decoration: none;
        color: #fff;
        font-weight: 400;
        font-family: 'Montserrat', sans-serif !important;
    }

    #cssmenu ul ul li a:hover,
    #cssmenu ul ul li a:focus {
        background: #fff !important;
        color: var(--red) !important
    }

    #cssmenu ul ul li:last-child>a,
    #cssmenu ul ul li.last-item>a {
        border-bottom: 0
    }

    #cssmenu ul ul li:hover>a,
    #cssmenu ul ul li a:hover {
        color: #fff
    }

    #cssmenu ul ul li.has-sub>a:after {
        position: absolute;
        top: 16px;
        right: 11px;
        width: 8px;
        height: 2px;
        display: block;
        background: #ddd;
        content: ''
    }

    #cssmenu ul ul li.has-sub>a:before {
        position: absolute;
        top: 13px;
        right: 14px;
        display: block;
        width: 2px;
        height: 8px;
        background: #ddd;
        content: '';
        transition: all .25s ease
    }

    #cssmenu ul ul>li.has-sub:hover>a:before {
        top: 17px;
        height: 0
    }

    .custnav #cssmenu>ul {
        background-color: #da291c;
        background-repeat: no-repeat;
        background-position: 80% 90%;
        background-image: url(https://ifixscreens.com/wp-content/uploads/2021/05/navigationBg.jpg);
        background-size: cover !important;
        list-style-type: none;
        top: 0;
        left: 0;
        padding: 0;
        position: fixed !important;
        overflow-x: hidden;
        overflow-y: auto;
        height: 100% !important;
        width: 100%;
        margin: 0;
        opacity: 0;
        visibility: hidden;
        transition-duration: .5s;
        transition-timing-function: ease;
        transition-property: opacity;
        display: block !important;
        padding-top: 50px !important;
        z-index: 99
    }

    .custmenu.isactive #cssmenu>ul.open,
    .custmenu.isactive #cssmenu>.main-menu {
        opacity: 1;
        visibility: visible
    }

    #cssmenu::after,
    #cssmenu>ul::after {
        content: ".";
        display: block;
        clear: both;
        visibility: hidden;
        line-height: 0;
        height: 0
    }

    .custmenu.isactive #cssmenu>ul.open,
    .custmenu.isactive #cssmenu>.main-menu {
        opacity: 1;
        visibility: visible
    }

    #cssmenu,
    #cssmenu ul,
    #cssmenu ul li,
    #cssmenu ul li a,
    #cssmenu #menu-button {
        margin: 0;
        padding: 0;
        border: 0;
        list-style: none;
        line-height: 1;
        display: block;
        position: relative;
        box-sizing: border-box
    }

    .custmenu #cssmenu ul li {
        opacity: 0;
        width: 100%;
        overflow: hidden;
        transform: translateY(5px) scale(.75);
        transform-origin: top center;
        transition: transform .25s ease-in-out, opacity .25s ease-in-out
    }

    .custmenu.isactive #cssmenu ul li {
        transform: scale(1);
        opacity: 1
    }

    #cssmenu ul li a {
        text-align: center !important;
        background-color: transparent;
        color: #fff;
        display: block;
        position: relative;
        padding: 12px 15px;
        font-size: 17px;
        letter-spacing: 1px;
        text-decoration: none;
        color: #fff;
        font-weight: 600;
        text-transform: uppercase;
        text-decoration: none;
        transition-duration: .2s;
        transition-timing-function: ease-in-out;
        transition-property: color, background-color;
        font-family: 'Montserrat', sans-serif !important;
    }

    #cssmenu ul li {
        width: 100%
    }

    #cssmenu ul ul li {
        width: 100% !important;
        padding: 0
    }

    #cssmenu ul ul li a {
        color: #fff;
        background: 0;
        font-size: 14px;
        letter-spacing: 0;
        width: 100%;
        padding: 11px 15px !important
    }

    #cssmenu ul ul li a:hover,
    #cssmenu ul ul li a:focus {
        background: #fff !important;
        color: var(--red) !important
    }

    #cssmenu ul ul {
        background: var(--red);
        display: none
    }

    #cssmenu ul .sub-menu.open {
        display: inline-block
    }
}

@media only screen and (min-width: 601px) and (max-width: 767px) {
    .ifsc_footer .custftrSec1 {
        padding: 30px 0 10px !important;
    }

    .ifsc_footer .custftrSec1>.wrapper {
        display: inline-block !important;
    }

    .ifsc_footer .custftrSec1>.wrapper>.ftrCol {
        margin: 0 0 30px 0 !important;
    }

    .ifsc_footer .custftrSec1>.wrapper>.ftrCol {
        margin: 0 2% 30px 0 !important;
        width: 48% !important;
    }

    #ifs_footer1 {
        width: 100% !important;
    }

    #subscribeForm {
        width: 100%;
    }

    .ifsc_footer .custftrSec1>.wrapper>.ftrCol {
        margin: 0 0 30px 0 !important;
    }

    .ifsc_footer .custftrSec1>.wrapper>.ftrCol {
        margin: 0 2% 30px 0 !important;
        width: 48% !important;
    }

    #ifs_footer2,
    #ifs_footer3 {
        width: 50% !important;
        display: inline-block;
        margin: 0 !important;
        padding: 0 10px 0 0;
    }

    .ifsc_footer .custftrSec1>.wrapper>.ftrCol {
        margin: 0 0 30px 0 !important;
    }

    .ifsc_footer .custftrSec1>.wrapper>.ftrCol {
        margin: 0 2% 30px 0 !important;
        width: 48% !important;
    }

    #ifs_footer4 {
        width: 100% !important;
    }

    .custftrBtmLft {
        width: 100%;
    }

    .custftrBtmRt {
        text-align: center;
        width: 100%;
    }

    .custftrBtmRt ul {
        width: 100%;
    }

    .custftrBtmRt ul li {
        margin: 0 10px !important;
    }
}

@media only screen and (min-width: 100px) and (max-width: 1100px) {
    .ifsc_footer .custftrSec1 {
        padding: 30px 0 10px !important;
    }

    .ifsc_footer .custftrSec1>.wrapper {
        display: inline-block !important;
    }

    .ifsc_footer .custftrSec1>.wrapper>.ftrCol {
        margin: 0 0 30px 0 !important;
    }

    .ifsc_footer .ttl {
        font-size: 16px !important;
    }

    #subscribeForm {
        width: 100%;
    }

    .ifsc_footer .custftrSec1>.wrapper>.ftrCol {
        margin: 0 0 30px 0 !important;
    }

    #ifs_footer2,
    #ifs_footer3 {
        width: 50% !important;
        display: inline-block;
        margin: 0 !important;
    }

    .ifsc_footer .ttl {
        font-size: 16px !important;
    }

    .ifsc_footer .ttl {
        font-size: 16px !important;
    }

    .ifsc_footer .custftrSec1>.wrapper>.ftrCol {
        margin: 0 0 30px 0 !important;
    }

    .custftrBtmLft {
        width: 100%;
    }

    .custftrBtmRt {
        text-align: center;
        width: 100%;
    }

    .custftrBtmRt ul {
        width: 100%;
    }

    .custftrBtmRt ul li {
        margin: 0 10px !important;
    }
}

@media only screen and (min-width: 100px) and (max-width: 767px) {
    .ifshdr .wrapper {
        padding: 0 5px !important;
    }
}

@media only screen and (min-width: 100px) and (max-width: 1100px) {
    .ifshdrTopmain ul {
        text-align: center;
    }

    .ifshdrTopmain ul li {
        margin: 0 !important;
    }

    .ifshdrTopmain ul li a {
        padding: 5px 5px !important;
    }

    .ifshdr {
        padding: 10px 0 2px;
    }

    .ifs_page_header .ifshdrmain {
        padding: 0;
    }

    .ifsHdrRt {
        width: 20%;
        float: left;
    }

    .custhdr {
        display: block;
    }

    #cssmenu #menu-button {
        background: #3d66ab;
        float: left;
    }

    #cssmenu ul ul {
        background: none !important;
    }

    .ifsMenu {
        display: none;
    }

    .ifslogo {
        width: 60%;
        text-align: center;
    }

    .ifslogo a {
        padding: 0 !important;
    }

    .ifslogo a {
        margin: 0;
    }

    .ifslogo a img,
    .ifslogo a svg {
        width: 190px;
    }

    .ifslogo a img,
    .ifslogo a svg {
        bottom: 0;
    }

    .ifs_page_header .ifslogo img {
        width: 190px;
    }

    .ifs_page_header .ifslogo img {
        float: left;
    }

    .custIfsloaction {
        display: inline-block;
    }
}

@media (max-width:1440px) {
    .devices_grid_item {
        width: calc(25% - 90px/4);
    }

    .issues_grid_item {
        width: calc(25% - 60px/4);
    }
}

@media (max-width:1280px) {
    .devices_grid_item {
        width: calc(33.3% - 60px/3);
    }

    .issues_grid_item {
        width: calc(33.3% - 40px/3);
    }
}

@media (max-width:1024px) {
    .devices_grid {
        gap: 10px;
        flex-wrap: wrap;
    }

    .steps_list_item_hdr .h1 {
        font-size: 30px;
        line-height: 35px;
    }

    .steps_list_item_hdr .h3 {
        font-size: 18px;
        line-height: 24px;
    }

    .steps_list_item_hdr .text {
        font-size: 14px;
        line-height: 20px;
    }

    .devices_grid_item {
        width: calc(50% - 10px/2);
        display: flex;
        padding: 20px;
        justify-content: space-between;
        flex-direction: row-reverse;
    }

    .devices_grid_item .image_wrap {
        width: 40px;
        height: 40px;
        margin: 0;
    }

    .devices_grid_item .image_wrap svg,
    .devices_grid_item .image_wrap img {
        width: 100%;
        height: 100%;
    }

    .devices_grid_item .text {
        margin: 0px 0;
        font-size: 18px;
    }

    .steps_list_item_row {
        gap: 0;
        flex-wrap: wrap-reverse;
    }

    .steps_list_item_row .fixed_side_half {
        order: -1;
        min-width: 100%;
        width: 100%;
        padding: 20px;
        border-left: none;
        border-bottom: 1px solid rgba(0, 0, 0, .1);
        border-top: 1px solid rgba(0, 0, 0, .1);
        padding-top: 40px;
    }

    .about_our_repairs_cards {
        gap: 20px 30px;
    }

    .about_our_repairs_card {
        width: calc(50% - 30px/2);
    }

    .action_button_list .action_button {
        width: calc(50% - 10px/2);
    }

    .issues_grid {
        gap: 20px;
        margin-bottom: 20px;
    }

    .issues_grid_item {
        width: calc(50% - 20px/2);
        display: flex;
        padding-left: 30px;
        padding-right: 30px;
        flex-direction: row-reverse;
        outline: 5px solid #ededed;
        box-shadow: 0 0 10px 1px rgba(0, 0, 0, .1);
        position: relative;
    }

    .issues_grid_item .image_wrap {
        min-width: 70px;
        max-width: 70px;
        height: 70px;
        outline: none;
        box-shadow: none;
        position: unset;
    }

    .issues_grid_item .text {
        text-align: left;
    }

    .optional_issues {
        margin-bottom: 10px;
    }

    .near_stores_list_item {
        padding-right: 35px;
    }

    .convenience_radio_list_item {
        width: calc(50% - 5px/2);
        min-height: 60px;
    }

    .near_stores_list {
        margin-bottom: 20px;
    }

    .dates_list_item {
        width: calc(25% - 15px/4);
    }

    .time_list_item {
        width: calc(50% - 10px/2);
    }
}

@media (max-width:767px) {

    .devices_grid {
        gap: 10px;
    }

    .steps_list_item_hdr .h1 {
        font-size: 30px;
        line-height: 35px;
    }

    .steps_list_item_hdr .h3 {
        font-size: 18px;
        line-height: 24px;
    }

    .steps_list_item_hdr .text {
        font-size: 14px;
        line-height: 20px;
    }

    .devices_grid_item {
        width: 100%;
        display: flex;
        padding: 20px;
        justify-content: space-between;
        flex-direction: row-reverse;
    }

    .devices_grid_item .image_wrap {
        width: 40px;
        height: 40px;
        margin: 0;
    }

    .devices_grid_item .image_wrap svg,
    .devices_grid_item .image_wrap img {
        width: 100%;
        height: 100%;
    }

    .devices_grid_item .text {
        margin: 0px 0;
        font-size: 18px;
    }

    .about_our_repairs_card {
        width: 100%;
    }

    .action_button_list .action_button {
        width: 100%;
    }

    .contact_form .input_row {
        flex-wrap: wrap;
    }

    .dates_list_item {
        width: calc(33.3% - 10px/3);
    }

    .time_list_item {
        width: calc(50% - 10px/2);
    }

    .convenience_radio_list_item {
        width: 100%;
        min-height: 50px;
    }
}

.step_brand_wrap select {
    font-family: 'Montserrat';
    outline: none;
    background-color: transparent;
    border: none;
    padding-left: 40px;
    position: absolute;
    top: 0;
    left: 0;
    width: 98%;
    height: 100%;
    padding-right: 15px;
    font-size: 15px;
}

.step_brand_wrap {
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 50px;
    padding: 10px;
    position: relative;
    display: flex;
    margin-bottom: 20px;
    gap: 10px;
    min-height: 50px;
}

.stores_wrap {
    margin-bottom: 20px;
    display: none;
}

.stores_wrap.active {
    display: block;
}

.contact_form .checkbox_list {
    margin-top: 20px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px 20px;
}

.contact_form .checkbox_list h4.heading {
    width: 100%;
    font-weight: 500;
    margin-bottom: 20px;
}

.contact_form .checkbox_list h4.heading {
    width: 100%;
    font-weight: 500;
}

span.error {
    color: var(--red);
    font-size: 12px;
    font-family: 'Montserrat';
}

.contact_form .checkbox_list span.error {
    display: block;
    width: 100%;
    margin-bottom: 10px;
}

.skeleton {
    position: relative;
    overflow: hidden;
    background-color: #ddd !important;
    color: #ddd !important;
    border-color: #ddd !important;
}

.skeleton:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .2) 20%, hsla(0, 0%, 100%, .5) 60%, hsla(0, 0%, 100%, 0));
    -webkit-animation: shimmer 5s infinite;
    animation: shimmer 5s infinite;
    content: "";
}

.skeleton * {
    opacity: 0
}

@-webkit-keyframes shimmer {
    to {
        transform: translateX(100%)
    }
}

@keyframes shimmer {
    to {
        transform: translateX(100%)
    }
}

.left_half_skeleton_one {
    height: 50px;
    border-radius: 8px;
    width: 100%;
    display: flex;
}

.left_half_skeleton_two {
    width: 80%;
    margin: 0 auto;
    height: 35px;
    border-radius: 8px;
    margin-top: 10px;
}

.left_half_skeleton_three {
    width: 100%;
    height: 100px;
    margin-top: 10px;
    border-radius: 8px;
}

.fixed_side_half_skeleton_one {
    height: 110px;
    border-radius: 8px;
    width: 100%;
    display: flex;
}

.fixed_side_half_skeleton_two {
    height: 110px;
    border-radius: 8px;
    width: 100%;
    display: flex;
    margin-top: 20px;
}

section.main_skeleton {
    width: 100%;
    min-height: 80vh;
}


@media (max-width: 1024px) {
    .step_navigation {
        position: fixed;
        bottom: 0;
        z-index: 100;
        background: #fff;
        width: 100%;
        left: 0;
        padding: 20px;
        box-shadow: 0 -5px 7px -4px rgba(0, 0, 0, 0.2);
        justify-content: space-between;
    }

    .steps_list_item_row .left_half {
        min-height: auto;
    }

    .summary_wrap .device_selected>.text {
        text-align: left;
    }

    .summary_wrap .device_selected>.image_wrap {
        margin: 0 0 15px;
    }
}

@media (max-width: 767px) {
    .issues_grid_item {
        width: 100%;
        align-items: center;
    }

    .issues_grid_item .text {
        width: calc(100% - 70px);
    }
}

.optional_issues {
    margin-bottom: 30px;
}


.slotserr {
    background: #f002;
    border: 1px solid var(--red);
    padding: 10px 20px;
    border-radius: 6px;
}

.fixed_steps_list_item {
    counter-increment: example-counter 1;

}

.fixed_steps_list_item .circle::before {
    content: counter(example-counter);
    color: var(--blue);
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    font-weight: 700;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.fixed_steps_list_item.active .circle::before {
    color: var(--white);
}

.fixed_steps_list_item.activechecked .circle {
    background-color: var(--blue);
}

.fixed_steps_list_item.activechecked .circle::before {
    content: '\f00c';
    color: #fff;
    font-family: 'FontAwesome';
}

#root {
    display: flex;
    flex-direction: column;
}


.contact_form .input {
    font-size: 14px;
}

.action_button_list .action_button .icon svg path {
    fill: var(--blue);
}

.about_our_repairs_card .icon svg {
    width: 40px;
    height: 40px;
    padding: 0;
}

.about_our_repairs_card .icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.use_location_toggle {
    margin-bottom: 20px;
}

.issues_grid_item .text {
    font-size: 15px;
}

.optional_issues textarea {
    font-size: 14px;
}

body {
    background-color: #fff !important;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --white: #fff;
    --red: #d90e16;
    --grey: #343434;
    --black: #000;
    --blue: #3d66ab;
    --lightgrey: #525657;
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 10px;
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 10px;
}

.main {
    display: flex;
    flex-direction: column;
}

.ifshdrRow {
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 1000;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    background-color: #fff;
}

.ifshdrTop {
    background: #3d66ab;
    width: 100%;
    float: left;
}

.ifshdrTop>.wrapper {
    min-height: 37px;
    padding: 0 15px;

}

.ifshdrTopmain {
    text-align: right;
}

.ifshdrTopmain ul {
    padding: 0;
    margin: 0;
}

.ifshdrTopmain ul li {
    list-style: none;
    display: inline-block;
    margin: 0;
}

.ifshdrTopmain ul li a {
    display: block;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px !important;
    padding: 5px 15px;
}

.ifshdrTopmain ul li a {
    font-weight: 700 !important;
    font-family: 'Montserrat', sans-serif !important;
}

body .ifshdrTopmain ul li a {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif !important;
}

.ifshdrTopmain ul li a span svg {
    width: 20px;
    vertical-align: top;
    height: auto;
    margin: 2px 0 0 0;
}

.ifshdrTopmain ul li:last-child {
    position: relative;
    z-index: 3;
}

.ifshdrTopmain ul li:last-child a {
    padding-right: 0 !important;
}

.ifshdr {
    width: 100%;
    float: left;
}

.ifshdr .wrapper,
.custftr .wrapper {
    max-width: 1250px !important;
    padding: 0 15px;

}

.ifshdrmain {
    width: 100%;
    float: left;
}

.ifs_page_header .ifshdrmain {
    padding: 15px 0;
}

.ifsHdrRt {
    width: 60%;
    float: right;
}

.custhdr {
    display: none;
}

.ifsHdrRt .wrapper {
    padding: 0 !important;
}

.ifsHdrRt .custnav {
    width: 100% !important;
}

#cssmenu #menu-button {
    float: left !important;
}

.ifshdr #cssmenu .submenu-button {
    height: 67px;
    width: 66px;
}

.ifshdr #cssmenu ul li a {
    font-family: 'Montserrat', sans-serif !important;
    padding: 22px 15px;
    font-size: 21px;
}

body .ifshdr #cssmenu ul li a {
    font-family: 'Montserrat', sans-serif !important;
}

.ifshdr #cssmenu ul ul li a {
    font-size: 18px !important;
    padding: 10px 0 !important;
}

.ifsMenu {
    width: 100%;
    float: left;
    margin: 55px 0 0 0;
}

.ifs_page_header .ifsMenu {
    margin: 30px 0 30px 0;
}

.ifs_page_header .ifslogo img,
.ifs_page_header .ifsMenu,
.ifs_home_header .ifslogo img,
.ifs_home_header .ifsMenu {
    height: auto;
    transition: ease-in-out .3s;
}

.ifshdrRow .ifsHdrRt .ifsMenu {
    text-align: right;
}

.ifsMenu>ul {
    padding: 0;
    margin: 0;
    text-align: right;
    position: relative;
}

.ifsMenu>ul>li {
    display: inline-block;
    margin: 0 18px !important;
}

.ifsMenu>ul>li {
    margin: 0 15px !important;
}

.ifsMenu>ul>li>a {
    display: inline-block;
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif !important;
    text-transform: uppercase;
}

#tve_thrive_lightbox_36378 .thrv_text_element p,
.NewHmBanr ul li h4,
.areas_serviced_main .areas_serviced_content,
.custfollowusnew .cff-header-text,
.custfollowusnew .cff-header-text p,
.custfollowusnew .cff-post-text,
.ifsGetintouchForm .tcb-plain-text>.tcb-plain-text,
.ifsMenu>ul>li>a,
.ifschwcboxTxt p,
.ifschwcboxhead h3,
.page-id-83623 .ftrCol .rcbli_right_text a,
.search-results #content :not(#tve) .tcb-post-content.tcb-shortcode.thrv_wrapper p {
    font-family: 'Montserrat', sans-serif !important;
}

#tve_thrive_lightbox_36378 .thrv_text_element p,
.NewHmBanr ul li h4,
.areas_serviced_main .areas_serviced_content,
.custfollowusnew .cff-header-text,
.custfollowusnew .cff-header-text p,
.custfollowusnew .cff-post-text,
.ifsGetintouchForm .tcb-plain-text>.tcb-plain-text,
.ifsMenu>ul>li>a,
.ifschwcboxTxt p,
.ifschwcboxhead h3,
.page-id-83623 .ftrCol .rcbli_right_text a,
.search-results #content :not(#tve) .tcb-post-content.tcb-shortcode.thrv_wrapper p {
    font-family: 'Montserrat', sans-serif !important;
}

body .ifsMenu>ul>li>a,
body .ifssubmenu>li>a>span {
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 600;
}

.ifs_page_header .ifsMenu>ul>li>a {
    color: #d90e16 !important;
}

.ifssubmenu {
    position: absolute;
    width: 100%;
    top: 100%;
    right: 0;
    display: none;
    z-index: 99;
    min-width: 710px;
    padding-top: 0 !important;
    margin: 0;
    box-shadow: rgba(0, 0, 0, .5) 1px 1px 4px;
    background: #fff;
}

.ifssubmenu,
.job_item_content ol,
.job_item_content ul {
    padding-left: 0;
}

.ifsMenu>ul>li:last-child {
    margin-right: 0 !important;
}

.ifx_searchli .search-form {
    right: 200px;
    top: 200px;
    margin: 0 !important;
}

.ifx_searchli .search-form label {
    cursor: pointer;
    width: 26px;
    display: inline-block;
}

.ifx_searchli .search-form label::before {
    content: "\f002";
    font-family: FontAwesome;
    color: #d90e16 !important;
    font-size: 19px;
}

.ifx_searchli .search-field {
    background-color: transparent;
    border: 2px solid transparent;
    height: 40px;
    transition: width .4s, background .4s;
    width: 0;
    cursor: pointer;
    position: absolute !important;
    right: 0 !important;
    background-image: none !important;
    margin: 0 !important;
    padding: 0 10px !important;
    top: -3px;
    box-shadow: none !important;
}

.ifx_searchli .search-form .search-submit {
    background: #e6e6e6;
    border: 1px solid #e6e6e6;
    color: rgba(0, 0, 0, .8);
    font-size: 1rem;
    line-height: 20px;
    padding: 10px 20px;
    display: none;
}

.custstartYrBtn {
    display: none;
}

.custIfsloaction {
    float: right;
    display: none;
}

.ifsMenu>ul>li:hover .ifssubmenu {
    display: block;
}

.ifssubmenu>li {
    padding: 0;
    background: #fff;
    opacity: 0;
    transform-origin: bottom;
    -webkit-animation: enter .2s ease forwards;
    animation: enter .2s ease forwards;
    margin: 0 !important;
    list-style: none;
    float: left;
    width: 20%;
    border-bottom: 1px solid #fff;
}

@keyframes enter {
    from {
        opacity: 0;
        transform: scaleY(0.98) translateY(10px)
    }

    to {
        opacity: 1;
        transform: none
    }
}

.ifssubmenu li:nth-child(1) {
    -webkit-animation-duration: .2s;
    animation-duration: .2s;
    -webkit-animation-delay: 0;
    animation-delay: 0
}

.ifssubmenu li:nth-child(2) {
    -webkit-animation-duration: .25s;
    animation-duration: .25s;
    -webkit-animation-delay: .1s;
    animation-delay: .1s
}

.ifssubmenu li:nth-child(3) {
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-animation-delay: .15s;
    animation-delay: .15s
}

.ifssubmenu li:nth-child(4) {
    -webkit-animation-duration: .35s;
    animation-duration: .35s;
    -webkit-animation-delay: .2s;
    animation-delay: .2s
}

.ifssubmenu li:nth-child(5) {
    -webkit-animation-duration: .4s;
    animation-duration: .4s;
    -webkit-animation-delay: .25s;
    animation-delay: .25s
}

.ifssubmenu li:nth-child(6) {
    -webkit-animation-duration: .45s;
    animation-duration: .45s;
    -webkit-animation-delay: .3s;
    animation-delay: .3s
}

.ifssubmenu li:nth-child(7) {
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    -webkit-animation-delay: .35s;
    animation-delay: .35s
}

.ifssubmenu li:nth-child(8) {
    -webkit-animation-duration: .55s;
    animation-duration: .55s;
    -webkit-animation-delay: .4s;
    animation-delay: .4s
}

.ifssubmenu li:nth-child(9) {
    -webkit-animation-duration: .6s;
    animation-duration: .6s;
    -webkit-animation-delay: .45s;
    animation-delay: .45s
}

.ifssubmenu li:nth-child(10) {
    -webkit-animation-duration: .65s;
    animation-duration: .65s;
    -webkit-animation-delay: .5s;
    animation-delay: .5s
}

.ifssubmenu li:nth-child(11) {
    -webkit-animation-duration: .7s;
    animation-duration: .7s;
    -webkit-animation-delay: .55s;
    animation-delay: .55s
}

.ifssubmenu>li>a {
    display: inline-block;
    padding: 0;
    font-size: 13px;
    width: 100%;
    text-align: center;
    text-decoration: none;
    color: #000;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif !important;
}

.ifssubmenu>li>a>svg {
    height: 60px;
    padding: 5px 10px 10px 0;
    margin-top: 20px;
    width: auto;
}

.ifssubmenu>li>a>span {
    display: inline-block;
    padding: 14px 0;
    background: #f8f8f8;
    width: 100%;
    line-height: normal;
    font-family: 'Montserrat', sans-serif !important;
    text-transform: none !important;
    font-size: 13px;
    font-weight: 600 !important;
}

.errfield {
    display: none;
}

.prtnrFrm {
    display: inline-block;
    width: 100%;
}

.errfieldshw {
    display: block;
}

.loading {
    height: 0;
    width: 0;
    padding: 15px;
    border: 6px solid #ccc;
    border-right-color: #888;
    border-radius: 22px;
    animation: rotate 1s infinite linear;
    -webkit-animation: rotate 1s infinite linear;
    /* left, top and position just for the demo! */
    position: absolute;
    left: 50%;
    top: 50%;
}

@keyframes rotate {

    /* 100% keyframe for  clockwise.
    use 0% instead for anticlockwise */
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes rotate {

    /* 100% keyframe for  clockwise.
    use 0% instead for anticlockwise */
    100% {
        -webkit-transform: rotate(360deg);
    }
}

.loading_wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #00000050;
    top: 0;
    left: 0;
    z-index: 100000000;
}

.custftr {
    width: 100%;
    float: left;
}

.custftr {
    position: relative;
    z-index: 2;
}

.custftrSec1 {
    width: 100%;
    float: left;
    background: #525657;
    padding: 50px 0;
    z-index: 2;
    position: relative;
}

.ifsc_footer .custftrSec1 {
    padding: 100px 0 50px !important;
    background-color: #212121 !important;
}

.ifsc_footer .custftrSec1>.wrapper {
    display: grid !important;
    grid-template-columns: 2fr 1fr 1fr 2fr !important;
}

.ftrCol {
    width: 22.7%;
    float: left;
    margin: 0 3% 0 0;
}

.ifsc_footer .custftrSec1>.wrapper>.ftrCol {
    width: 100% !important;
    margin: 0 !important;
    padding-right: 10px;
}

.ftrCol .ttl {
    width: 100%;
    float: left;
    margin-bottom: 20px !important;
    color: #e6e6e6;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
}

.ifsc_footer .ttl {
    position: relative;
    padding-bottom: 20px !important;
    font-size: 21px !important;
    line-height: 25px !important;
    text-transform: capitalize !important;
    font-family: 'Montserrat', sans-serif !important;
}

.ifsc_footer .ttl {
    font-family: 'Montserrat', sans-serif !important;
}

.ifsc_footer .ttl::after {
    content: '';
    position: absolute;
    width: 10%;
    height: 5px;
    left: 0;
    bottom: 0;
    background: var(--red);
}

.textwidget {
    width: 100%;
    float: left;
}

#subscribeForm {
    width: 80%;
}

.ifschowwecanRow.newlist,
#subscribeForm .inpcol {
    display: block !important;
}

#subscribeForm .isRequired {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -ms-box-shadow: none !important;
    -o-box-shadow: none !important;
    -webkit-box-shadow: none !important;
}

#subscribeForm .isRequired {
    width: 100%;
}

.errfield {
    font-size: 13px;
    color: red;
    line-height: normal;
    margin: 0;
}

.errfield {
    display: none;
}

#subscribeForm .errfield {
    width: 100%;
}

#subscribeForm .tve-froala {
    cursor: pointer;
    outline: 0 !important;
    margin: 10px 0;
}

.ifsc_footer .tve-froala {
    transition: all .35s;
    width: 100%;
    padding: 12px 20px !important;
    font-weight: 700 !important;
    text-transform: uppercase !important;
    font-size: 14px !important;
    border-radius: 0 !important;
    text-decoration: none !important;
    border: 2px solid #d90e16 !important;
    background: #d90e16 !important;
    color: #fff !important;
    font-family: 'Montserrat', sans-serif !important;
}

/* aasdadada */







* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.steps_list_main_wrap {
    display: flex;
}

.steps_list_main {
    min-width: 100%;
}

.steps_list_item_hdr .h1 {
    font-size: 40px;
    line-height: 50px;
    color: #3e67ac;
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    margin: 0 0 10px;
    text-align: center;
}

.steps_list_item_hdr .h3 {width: 100%;
    font-size: 22px;
    line-height: 35px;
    color: #3e67ac;
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    margin: 0;
    margin: 0 0 10px 0;
    text-align: center;
}

.steps_list_item_hdr .text {
    margin: 5px 0 10px 0;
    color: #3e67ac;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
}

.dont_see {
    margin: 5px 0 10px 0;
    color: #333;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
}

.dont_see a {
    display: block;
    color: inherit;
}

.devices_grid {
    margin: 20px 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
}

.devices_grid_item * {
    pointer-events: none;
}

.devices_grid_item {
    width: calc(20% - 120px/5);
    border: 5px solid #ebebeb;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, .1);
    cursor: pointer;
    background-color: #fff;
    padding: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    gap: 20px;
}

.devices_grid_item.active::before {
    opacity: 0.5;
    pointer-events: all;
}

.devices_grid_item::before {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    pointer-events: none;
    height: 100%;
    background-color: #3e67ac;

}

.devices_grid_item .image_wrap {
    width: 110px;
    height: 110px;
    margin: 0 auto;
    display: flex;
}

.devices_grid_item .image_wrap svg {
    width: 100%;
    height: 100%;
}

.devices_grid_item .text {
    line-height: 1.1;
    font-weight: 600;
    color: #000;
    margin: 25px 0;
    white-space: nowrap;
    font-family: 'Montserrat', sans-serif !important;
    font-size: 18px;
}

.second .steps_list_item_hdr .h1 {
    text-align: left;
}

.second .steps_list_item_hdr .h3 {
    text-align: left;
}

.search_form .input_wrap {
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 50px;
    padding: 10px;
    position: relative;
    display: flex;
    gap: 10px;
}

.search_form .input_wrap .icon {
    min-width: 20px;
    height: 20px;
    display: flex;
}

.search_form .input_wrap .icon svg {
    width: 20px;
    height: 20px;
}

.search_form .input_wrap .text::-webkit-input-placeholder {
    opacity: 1;
}

.search_form .input_wrap .text::-moz-placeholder {
    opacity: 1;
}

.search_form .input_wrap .text:-ms-input-placeholder {
    opacity: 1;
}

.search_form .input_wrap .text::-ms-input-placeholder {
    opacity: 1;
}

.search_form .input_wrap .text::placeholder {
    opacity: 1;
}

.search_form .input_wrap .text {
    font-family: 'Montserrat';
    outline: none;
    background-color: transparent;
    border: none;
    padding-left: 40px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-right: 15px;
}

.or_divider {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    gap: 20px;
    color: rgba(0, 0, 0, .6);
    font-family: 'Montserrat';
}

.or_divider::before,
.or_divider::after {
    content: '';
    display: flex;
    width: 100%;
    height: 2px;
    background-color: rgba(0, 0, 0, .1);
}

.use_location_toggle {
    display: flex;
    align-items: center;
    font-family: 'Montserrat';
    justify-content: center;
    gap: 10px;
    color: rgba(0, 0, 0, .6);
}

.use_location_toggle .icon {
    width: 20px;
    height: 20px;
}

.use_location_toggle .icon svg {
    width: 20px;
    height: 20px;
}

.steps_list_item_row {
    width: 100%;
    display: flex;
    gap: 50px;
}

.steps_list_item_row .left_half {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    width: 100%;
    padding: 50px 0;
}

.steps_list_item_row .fixed_side_half {
    min-width: 400px;
    padding: 30px 50px;
    border-left: 1px solid rgba(0, 0, 0, .1);
}

.steps_list_item.first {
    padding: 50px 0;
}

.fixed_steps_list::before {
    content: '';
    width: 100%;
    height: 2px;
    background-color: #ddd;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
}


.fixed_steps_list {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 25px;
}

.fixed_steps_list_item {
    position: relative;
    z-index: 1;
}

.issues_grid_item.active .image_wrap::before {
    opacity: 0.5;
    pointer-events: all;
}

.issues_grid_item .image_wrap::before {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    pointer-events: none;
    height: 100%;
    z-index: 1;
    background-color: #3e67ac;
}

.fixed_steps_list .circle {
    outline: 2px solid #3e67ac;
    display: flex;
    border-radius: 50%;
    min-width: 35px;
    height: 35px;
    cursor: pointer;
    background-color: #ddd;
}

.fixed_steps_list .active .circle {
    outline-color: #ddd;
    background-color: #3e67ac;
}

.summary_wrap {
    position: relative;
    padding-bottom: 40px;
    border-bottom: 2px solid #ddd;
}

.pricing_details {
    position: relative;
    padding-bottom: 40px;
    padding-top: 20px;
    border-bottom: 2px solid #ddd;
}

.need_to_schedule {
    position: relative;
    padding-bottom: 40px;
    padding-top: 40px;
}

.summary_wrap>.heading,
.visit_details>.heading,
.about_our_repairs>.heading,
.pricing_details>.heading,
.need_to_schedule>.heading {
    color: #3e67ac;
    font-family: 'Montserrat';
    font-weight: 600;
    margin-bottom: 20px;
}

.summary_wrap .summary_wrap_row {
    display: flex;
    justify-content: space-between;
}

.summary_wrap .device_selected {
    display: flex;
    align-items: center;
}

.summary_wrap .device_selected>.image_wrap {
    min-width: 80px;
    max-width: 80px;
    height: 80px;
    display: flex;
    margin: 0 auto 0px;
}

.summary_wrap .device_selectedTextCol .service_title {
    font-weight: 600;
    font-family: 'Montserrat';
}

.summary_wrap .device_selectedTextCol .text {
    /* font-weight: 600; */
    font-size: 14px;
}

.summary_wrap .device_selected>.image_wrap svg {
    width: 100%;
    height: 100%;
}

.summary_wrap .device_selected>.text {
    text-align: center;
    font-family: 'Montserrat';
    font-size: 14px;
    color: #333;
}

.summary_wrap .device_other_details {
    width: auto;
    text-align: center;
}

.summary_wrap .device_other_details .inner {
    display: flex;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}

.summary_wrap .device_other_details .selected_model,
.summary_wrap .device_other_details .selected_model_issue {
    color: #333;
    font-size: 13px;
    font-family: 'Montserrat';
    font-weight: 600;
}

.summary_wrap .device_other_details .change_button {
    cursor: pointer;
    color: #333;
    font-size: 13px;
    font-family: 'Montserrat';
    font-weight: 600;
}

.step_navigation {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: flex-end;
}

.step_navigation {
    margin-top: auto;
}

.step_navigation_button {
    outline: 1px solid var(--blue);
    border-radius: 50px;
    color: var(--blue);
    font-family: 'Montserrat';
    font-weight: normal;
    display: inline-flex;
    padding: 10px 30px;
    cursor: pointer;
}

.step_navigation_button.continue,
.step_navigation_button:hover {
    outline-color: #3e67ac;
    background-color: #3e67ac;
    color: #fff;
}

.checkbox_list_item {
    /* margin-bottom: 15px; */
}

.checkbox_list_item input[type="checkbox"] {
    display: none;
}

.checkbox_list_item input[type="checkbox"]:checked~label::after {
    opacity: 1;
}



.checkbox_list_item label {
    padding-left: 30px !important;
    min-height: 19px;
    position: relative;
    color: #333;
    display: flex;
    font-family: 'Montserrat';
    font-weight: 600;
}

.visit_details {
    padding: 40px 0;
    border-bottom: 2px solid #ddd;
}

.about_our_repairs {
    padding: 40px 0;
}

.issues_grid {
    display: flex;
    flex-wrap: wrap;
    gap: 0px 20px;
    margin-top: 30px;
}

.issues_grid_item {
    cursor: pointer;
    width: calc(20% - 80px/5);
}

.issues_grid_item .image_wrap {
    position: relative;
    width: 100%;
    outline: 5px solid #ededed;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, .1);
}

.issues_grid_item .text {
    width: 100%;
    line-height: 1.1;
    font-weight: 600;
    color: #000;
    margin: 20px 0 20px;
    font-family: 'Montserrat', sans-serif !important;
    font-size: 18px;
    text-align: center;
}

.issues_grid_item .image_wrap img {
    display: flex;
    width: 100%;
    padding: 17%;
}

.optional_issues textarea {
    font-family: 'Montserrat';
    padding: 20px;
    outline: none;
    border: 2px solid #ddd;
    border-radius: 30px;
    min-height: 150px;
    resize: none;
    width: 100%;
}

.textarea_characters_limit {
    font-family: 'Montserrat';
    color: #3338;
    text-align: right;
    margin-top: 10px;
}

.nearest_locations {
    font-size: 17px;
    color: #333;
    font-family: 'Montserrat';
}

.nearest_locations .change_location {
    cursor: pointer;
    text-decoration: underline;
    display: inline-flex;
}

.convenience_radio_list {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 25px;
}

.convenience_radio_list_item.active {
    border-color: #3e67ac;
}

.convenience_radio_list_item {
    border-radius: 5px;
    width: calc(33.3% - 10px/3);
    padding: 10px 20px 10px 10px;
    border: 2px solid #ddd;
    display: inline-flex;
    align-items: center;
    color: #3337;
    font-size: 12px;
    font-family: 'Montserrat';
    gap: 7px;
}

.convenience_radio_list_item::before {
    content: '';
    display: inline-flex;
    min-width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid #ddd;
    background-color: transparent;
}

.convenience_radio_list_item.active::before {
    background-color: #3e67ac;
}

.stores_near_you {
    margin-top: 25px;
    padding: 20px 0;
    border-top: 2px solid #ddd;
}

.stores_near_you_number {
    margin-bottom: 20px;
    font-family: 'Montserrat';
    color: #333;
}

.map_wrap {
    width: 100%;
}

.map_wrap iframe {
    width: 100%;
    border: none;
    height: 175px;
}

.near_stores_list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.near_stores_list_item::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #3339;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: rotate(90deg) translateY(-50%) translateX(-8px);
}

.near_stores_list_item.active::before {
    background-color: #3e67ac;
    outline: #3e67ac;
}

.near_stores_list_item::before {
    content: '';
    position: absolute;
    --size: 25px;
    width: var(--size);
    height: var(--size);
    outline: 2px solid #ddd;
    border-radius: var(--size);
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
}

.near_stores_list_item {
    cursor: pointer;
    position: relative;
    border: 2px solid #ddd;
    padding: 20px;
    padding-left: 45px;
    border-radius: 20px;
    font-family: 'Montserrat';
}

.near_stores_list_item .store_name {
    font-size: 20px;
    color: #3338;
    font-weight: 600;
    display: inline-flex;
    margin-right: 15px;
}

.near_stores_list_item .store_distance {
    display: inline-flex;
    display: none;
    font-size: 12px;
    color: #3338;
    font-weight: 400;
}

.near_stores_list_item .store_address {
    font-size: 14px;
    color: #3338;
    font-weight: 600;
    margin-bottom: 5px;
}

.near_stores_list_item .next_date_available {
    font-size: 14px;
    color: #3338;
    font-weight: 600;
}

.near_stores_list_item .next_date_available b {
    color: #3e67ac;
}

.store_selected {
    font-family: 'Montserrat';
    color: #3338;
    font-size: 12px;
    margin-bottom: 5px;
}

.choose_a_date,
.choose_time {
    padding: 10px;
    margin-bottom: 10px;
}

.choose_a_date h5,
.choose_time h5 {
    font-family: 'Montserrat';
    margin-bottom: 10px;
}

.dates_list {
    flex-wrap: wrap;
    display: flex;
    gap: 5px;
}

.dates_list_item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 2px solid #3332;
    padding: 15px;
    min-width: 93px;
    min-height: 90px;
    overflow: hidden;
    cursor: pointer;
}

.dates_list_item.closed::before {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    content: '';
    opacity: 0.5;
    background-color: #ddd;
}

.dates_list_item.closed .today {
    background-color: #ddd;
}

.dates_list_item .today {
    background-color: #f00;
    color: #fff;
    font-family: 'Montserrat';
    font-size: 11px;
    border-radius: 4px;
    padding: 2px 5px;
    margin-top: -18px;
}

.dates_list_item:hover,
.dates_list_item.active {
    border-color: #000;
}

.dates_list_item:hover *,
.dates_list_item.active * {
    pointer-events: none;
    position: relative;
    z-index: 1;
}

.dates_list_item:hover:before,
.dates_list_item.active::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #3e67ac;
    opacity: 0.5;
}

.dates_list_item .day {
    font-weight: 600;
    font-size: 14px;
    font-family: 'Montserrat';
}

.dates_list_item .date {
    font-weight: 500;
    font-size: 12px;
    font-family: 'Montserrat';
}

.time_list {
    display: flex;
    flex-wrap: wrap;
    gap: 5px 10px;
}

.time_list_item.active * {
    position: relative;
    z-index: 1;
    pointer-events: none;
}

.time_list_item:hover:before,
.time_list_item.active::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #3e67ac;
    top: 0;
    left: 0;
    opacity: 0.5;
}

.time_list_item {
    white-space: nowrap;
    cursor: pointer;
    width: calc(20% - 40px/5);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 2px solid #3332;
    padding: 15px;
    overflow: hidden;
    font-weight: 500;
    font-size: 12px;
    font-family: 'Montserrat';
}

.contact_form {
    margin-bottom: 15px;
}

.contact_form .input_row {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.contact_form .input_column {
    width: 100%;
}

.contact_form .input::-webkit-input-placeholder {
    opacity: 1;
    color: #33333390;
    font-weight: 600;

}

.contact_form .input::-moz-placeholder {
    opacity: 1;
    color: #33333390;
    font-weight: 600;

}

.contact_form .input:-ms-input-placeholder {
    opacity: 1;
    color: #33333390;
    font-weight: 600;

}

.contact_form .input::-ms-input-placeholder {
    opacity: 1;
    color: #33333390;
    font-weight: 600;

}

.contact_form .input::placeholder {
    opacity: 1;
    color: #33333390;
    font-weight: 600;

}

.contact_form .help_text {
    font-family: 'Montserrat';
    font-size: 12px;
    color: #33333389;
    font-weight: 600;
    padding: 4px 12px;
}

.contact_form .input {
    outline: none;
    width: 100%;
    border: 1px solid #3337;
    height: 60px;
    border-radius: 10px;
    padding: 10px;
    font-family: 'Montserrat';
    color: #333;
}

.agree_row p {
    color: #333;
    font-family: 'Montserrat';
    font-weight: 600;
}

.agree_row p a {
    color: inherit;
}

.contact_form .checkbox_list {
    margin-top: 20px;
    margin-bottom: 10px;
}

.contact_form .checkbox_list_item label {
    color: #333;
    font-family: 'Montserrat';
    font-weight: 600;
}

.succes_banner .image_wrap {
    margin-bottom: 15px;
}

.succes_banner .image_wrap img {
    width: 100%;
    display: flex;
}

.action_button_list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.action_button_list .action_button {
    width: calc(33.3% - 20px/3);
    border: 2px solid #3e67ac;
    height: 50px;
    font-weight: 600;
    color: #3e67ac;
    font-size: 14px;
    font-family: 'Montserrat';
    border-radius: 50px;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    text-decoration: none;

}

.action_button_list .action_button .icon {
    display: flex;
    --size: 25px;
    width: var(--size);
    height: var(--size);
}

.action_button_list .action_button .icon svg {
    width: 100%;
    height: 100%;
}

.action_button_list .action_button.active {
    background-color: #3e67ac;
    color: #fff;
}

.about_our_repairs_sec {
    padding: 40px 0;
}

.about_our_repairs_sec>.heading {
    font-family: 'Montserrat';
    font-weight: 600;
    color: #000;
    font-size: 20px;
    margin-bottom: 20px;
}

.about_our_repairs_cards {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 50px;
}

.about_our_repairs_card {
    width: calc(33.3% - 100px/3);
}

.about_our_repairs_card .icon {
    --size: 70px;
    width: var(--size);
    height: var(--size);
    background-color: #eee;
    border-radius: var(--size);
    margin-bottom: 10px;
}

.about_our_repairs_card .icon svg {
    width: 100%;
    height: 100%;
    padding: 5px;
}

.about_our_repairs_card .name {
    font-family: 'Montserrat';
    color: #000;
    font-weight: 600;
    font-size: 17px;
}

.about_our_repairs_card .desc {
    font-family: 'Montserrat';
    color: #000;
    font-weight: 400;
    font-size: 14px;
}

.pricing_details .pricing {
    padding: 10px 20px;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    gap: 12px;
}

.summary_bx h3 {
    color: #333;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 18px;
}

.summary_bx p {
    color: #3337;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 15px;
    text-align: right;
}

.summary_bx {
    display: flex;
    gap: 10px;
    justify-content: space-between;
}

.pricing_details .pricing .label {
    padding: 10px 20px;
    font-family: 'Montserrat';
    color: #33333399;
    font-weight: 700;
}

.ifsc_footer .ttl::after {
    content: '';
    position: absolute;
    width: 10%;
    height: 5px;
    left: 0;
    bottom: 0;
    background: var(--red);
}

.pricing_details .pricing .label .help_text {
    font-size: 10px;
    line-height: 1.6;
    color: #3337;
    font-weight: 600;
    margin-top: 20px;
}

.pricing_details .pricing .value {
    padding: 10px 20px;
    white-space: nowrap;
    font-family: 'Montserrat';
    color: #33333399;
    font-weight: 700;
}

.need_to_schedule p {
    color: #3337;
    font-family: 'Montserrat';
    font-weight: 600;
}

.step_navigation_button.continue.disabled {
    background: #000;
    opacity: 0.5;
    cursor: not-allowed;
}

#subscribeForm .isRequired {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -ms-box-shadow: none !important;
    -o-box-shadow: none !important;
    -webkit-box-shadow: none !important;
}

#subscribeForm .isRequired {
    height: auto !important;
    font-size: 14px !important;
    padding: 12px !important;
    line-height: normal !important;
    box-shadow: none !important;
    margin: 10px 0;
}

.ftrCol .menu li {
    list-style: none;
    width: 100%;
    margin: 0;
    border-bottom: 1px dotted #242424;
}

.ftrCol .menu li {
    color: rgba(10, 10, 10, .85);
    font-family: Arial, sans-serif;
    font-size: 1rem;
    line-height: 1.75;
    letter-spacing: normal;
    font-weight: 400;
    margin: 5px 0 !important;
}

.ftrCol .menu {
    padding: 0;
    margin: 0;
    width: 100%;
    float: left;
}

.ftrCol .menu li a {
    color: #fff;
    font-size: 14px;
    line-height: 18px;
    padding: 8px 10px 8px 25px;
    position: relative;
    display: inline-block;
    width: 100%;
}

.ifsc_footer .ftrCol .menu li a {
    text-decoration: none;
    padding: 0 !important;
    font-family: 'Montserrat', sans-serif !important;
}

.ifsc_footer .ftrCol .menu li a,
.ifsc_rcbli_text a,
.ifsc_footer .custftrBtmLft p,
.ifsc_footer .ftrCol .textwidget p {
    font-family: 'Montserrat', sans-serif !important;
}

.ftrCol .menu li a::after {
    left: 5px;
    right: auto;
    font-size: 17px;
    top: 6px;
    color: #fff;
    content: '\000BB';
    font-family: "FontAwesome";
    position: absolute;
}

.ifsc_footer .ftrCol .menu li a::after {
    display: none !important;
}

.ftrCol .menu li a:hover {
    background: #242424;
    color: #e6e6e6;
    text-decoration: none;
}

.ftrCol .menu li a:hover {
    background: 0 0 !important;
    color: var(--red) !important;
}

.recent_blog_post {
    width: 100%;
    float: left;
}

.ifsc_rcbli {
    width: 100%;
    float: left;
    line-height: normal;
    margin-bottom: 10px;
}

.ifsc_rcbli_text {
    width: 100%;
    float: left;
}

.ifsc_rcbli_text a {
    display: inline-block;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif !important;
    color: #fff;
    font-size: 14px;
    line-height: 22px;
    position: relative;
}

.ifsc_rcbli_text a:hover {
    color: var(--red) !important;
    text-decoration: none !important;
}

.custftrBtm {
    width: 100%;
    float: left;
    background: #242424;
    padding: 25px 0 35px !important;
}

.ifsc_footer .custftrBtm {
    padding: 25px 0 !important;
    border-top: 1px dashed #383838;
    background: #212121;
}

.custftrBtmLft {
    float: left;
    width: 70%;
    color: #8c8989;
    font-size: 12px;
}

.custftrBtmLft p {
    font-size: 13px !important;
    line-height: 18px !important;
    color: #fff !important;
    margin: 0 0 10px !important;
    padding: 0 !important;
}

.ifsc_footer .custftrBtmLft p,
.ifsc_footer .ftrCol .textwidget p {
    font-size: 14px !important;
    line-height: 1.8 !important;
    font-family: 'Montserrat', sans-serif !important;
}

.ifsc_footer .ftrCol .menu li a,
.ifsc_rcbli_text a,
.ifsc_footer .custftrBtmLft p,
.ifsc_footer .ftrCol .textwidget p {
    font-family: 'Montserrat', sans-serif !important;
}

.custftrBtmRt ul {
    float: right;
    margin: 30px 0 0;
    padding: 0;
}

.custftrBtmRt ul li {
    margin: 0 0 0 15px;
    margin-left: 1.5em;
    display: inline-block;
}

.custftrBtmRt ul li a {
    color: #fff;
}

.cs-main,
.custftrBtmLft a {
    color: #fff;
    text-decoration: none;
}

.ftrCol .textwidget p {
    font-size: 14px;
    color: #fff;
    width: 100%;
    float: left;
    padding: 0;
}

.ifsc_footer .custftrBtmLft p,
.ifsc_footer .ftrCol .textwidget p {
    font-size: 14px !important;
    line-height: 1.8 !important;
    font-family: 'Montserrat', sans-serif !important;
}

.ifsc_footer .ftrCol .menu li a,
.ifsc_rcbli_text a,
.ifsc_footer .custftrBtmLft p,
.ifsc_footer .ftrCol .textwidget p {
    font-family: 'Montserrat', sans-serif !important;
}

.ftrCol .textwidget p a {
    color: #fff !important;
    text-decoration: none !important;
}

.ftrCol .textwidget p a:hover,
.ftrCol .textwidget p a:focus {
    color: var(--red) !important;
    text-decoration: none !important;
}

#subscribeForm .tve-froala:hover,
#subscribeForm .tve-froala:focus {
    background: #fff !important;
    border: 2px solid var(--red) !important;
    color: var(--red) !important;
    text-decoration: none !important;
}

.custIfsloaction a {
    color: #3d66ab;
    display: inline-block;
    margin: 10px 0 0 0;
}

.custIfsloaction a svg {
    width: 24px;
}

.ifslogo {
    width: 40%;
    float: left;
    background: #fff;
    position: relative;
    z-index: 9;
    margin: 0 0 -2px 0;
    padding: 0 0 2px 0;
}

.ifs_page_header .ifslogo {
    padding: 0;
    margin: 0;
    background: 0;
}

.ifshdr .wrapper,
.custftr .wrapper {
    max-width: 1250px !important;
}

.ifsHdrRt .wrapper {
    padding: 0 !important;
}

.ifshdr #cssmenu #menu-button.menu-opened {
    left: 10px !important;
    right: auto !important;
}

.navmenubtnstart {
    margin: 60px 0 0 !important;
    text-align: center;
}

.navmenubtnstart a {
    padding: 26px 60px !important;
    background: #3e67ac !important;
    font-weight: 600 !important;
}

.navmenubtnstart a {
    border-radius: 50px !important;
    font-size: 20px !important;
    color: #fff;
    width: auto !important;
    display: inline-block !important;
}

.navmenubtnstart a:focus,
.navmenubtnstart a:hover {
    background: #000 !important;
    color: #fff !important;
}

.ifslogo a img,
.ifslogo a svg {
    width: 350px;
    position: relative;
    bottom: -30px;
}

.ifs_page_header .ifslogo img {
    bottom: 0;
    width: 350px;
}

.ifslogo a {
    display: inline-block;
    padding: 0 5px 14px 0;
    margin: 0 0 -33px 0;
    position: relative;
    z-index: 2;
}

.ifs_page_header .ifslogo a {
    padding: 0;
}

@media only screen and (min-width: 100px) and (max-width: 767px) {
    body .navmenubtnstart a {
        font-size: 16px !important;
    }
}

@media all and (max-width:1199px),
only screen and (-webkit-min-device-pixel-ratio:2) and (max-width:1199px),
only screen and (min-device-pixel-ratio:2) and (max-width:1199px),
only screen and (min-resolution:192dpi) and (max-width:1199px),
only screen and (min-resolution:2dppx) and (max-width:1199px) {
    .ifshdr {
        box-shadow: rgba(0, 0, 0, .3) 0 0 3px;
        -moz-box-shadow: rgba(0, 0, 0, .3) 0 0 3px;
        -ms-box-shadow: rgba(0, 0, 0, .3) 0 0 3px;
        -o-box-shadow: rgba(0, 0, 0, .3) 0 0 3px;
        -webkit-box-shadow: rgba(0, 0, 0, .3) 0 0 3px
    }

    #cssmenu {
        width: 100%
    }

    #cssmenu #menu-button {
        background: #3d66ab;
        border: 0;
        display: inline-block !important;
        top: 8px !important;
        position: relative;
        right: 0;
        width: 40px !important;
        height: 40px !important;
        background-image: url(https://ifixscreens.com/storage/2020/12/menuIcon.png) !important;
        background-size: 22px !important;
        background-repeat: no-repeat !important;
        background-position: center !important;
        font-size: 0;
        cursor: pointer;
        z-index: 9999
    }

    .custmenu.isactive #cssmenu #menu-button {
        width: 30px !important;
        height: 30px !important;
        background: none !important
    }

    #cssmenu #menu-button::before {
        position: absolute;
        right: 0;
        left: 0;
        display: block;
        height: 2px;
        width: 20px;
        background: #fff;
        content: '';
        opacity: 0
    }

    #cssmenu #menu-button.menu-opened::before {
        top: 18px;
        background: #fff;
        width: 30px;
        transform: rotate(-45deg)
    }

    #cssmenu #menu-button::after {
        position: absolute;
        display: block;
        content: '';
        right: 0
    }

    #cssmenu #menu-button.menu-opened::after {
        top: 18px;
        border: 0;
        height: 2px;
        width: 30px;
        background: #fff;
        transform: rotate(45deg)
    }

    #cssmenu #menu-button.menu-opened::before,
    #cssmenu #menu-button.menu-opened::after {
        opacity: 1
    }

    #cssmenu #menu-button.menu-opened {
        position: fixed;
        background-image: none;
        top: 5px !important;
        right: 10px !important
    }

    #cssmenu .submenu-button {
        position: absolute;
        z-index: 99;
        right: 0;
        top: 0;
        display: block;
        height: 42px;
        width: 46px;
        cursor: pointer
    }

    #cssmenu .submenu-button::before {
        position: absolute;
        top: 19px;
        right: 22px;
        display: block;
        width: 2px;
        height: 8px;
        background: #fff;
        content: ''
    }

    #cssmenu .submenu-button::after {
        position: absolute;
        top: 22px;
        right: 19px;
        width: 8px;
        height: 2px;
        display: block;
        background: #fff;
        content: ''
    }

    #cssmenu .submenu-button.submenu-opened {
        background: #d90e16
    }

    #cssmenu .submenu-button.submenu-opened::after {
        background: #fff
    }

    .ifshdr #cssmenu ul li:hover .submenu-button:after,
    .ifshdr #cssmenu ul li:focus .submenu-button:after {
        background: var(--red) !important
    }

    .ifshdr #cssmenu ul li:hover .submenu-button:before,
    .ifshdr #cssmenu ul li:focus .submenu-button:before {
        background: var(--red) !important
    }

    #cssmenu .submenu-button.submenu-opened:focus::after {
        background: #d90e16 !important
    }

    .ifshdr #cssmenu ul li:hover .submenu-button.submenu-opened:before,
    .ifshdr #cssmenu ul li .submenu-button.submenu-opened:before {
        background: #fff !important
    }

    .ifshdr #cssmenu ul li:hover .submenu-button.submenu-opened:after {
        background: #fff !important
    }

    #cssmenu>ul>li.has-sub>a::after {
        display: none
    }

    #cssmenu:after,
    #cssmenu>ul:after {
        content: ".";
        display: block;
        clear: both;
        visibility: hidden;
        line-height: 0;
        height: 0
    }

    #cssmenu ul ul {
        top: 0;
        margin: 0;
        background: #fff;
        max-width: 100%;
        width: 100%;
        z-index: 3;
        visibility: visible;
        position: relative;
        left: 0
    }

    .custmenu #cssmenu ul li {
        width: 100%
    }

    #cssmenu ul ul li a::before {
        display: none
    }

    #cssmenu>ul>li {
        float: left;
        width: 100%;
        margin: 0;
        font-family: 'Montserrat', sans-serif !important;
    }

    #cssmenu>ul>li>a {
        padding: 12px 0;
        font-size: 18px;
        letter-spacing: 1px;
        text-decoration: none;
        color: #fff;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif !important;
    }

    #cssmenu>ul>li:hover>a {
        background: #fff;
        color: var(--red)
    }

    #cssmenu>ul>li:hover>a {
        background: #fff;
        color: var(--red)
    }

    #cssmenu ul ul ul {
        margin-left: 100%;
        top: 0
    }

    #cssmenu ul ul li a {
        border-bottom: 1px solid rgba(150, 150, 150, 0.15);
        padding: 11px 15px;
        width: 170px;
        font-size: 14px;
        text-decoration: none;
        color: #fff;
        font-weight: 400;
        font-family: 'Montserrat', sans-serif !important;
    }

    #cssmenu ul ul li a:hover,
    #cssmenu ul ul li a:focus {
        background: #fff !important;
        color: var(--red) !important
    }

    #cssmenu ul ul li:last-child>a,
    #cssmenu ul ul li.last-item>a {
        border-bottom: 0
    }

    #cssmenu ul ul li:hover>a,
    #cssmenu ul ul li a:hover {
        color: #fff
    }

    #cssmenu ul ul li.has-sub>a:after {
        position: absolute;
        top: 16px;
        right: 11px;
        width: 8px;
        height: 2px;
        display: block;
        background: #ddd;
        content: ''
    }

    #cssmenu ul ul li.has-sub>a:before {
        position: absolute;
        top: 13px;
        right: 14px;
        display: block;
        width: 2px;
        height: 8px;
        background: #ddd;
        content: '';
        transition: all .25s ease
    }

    #cssmenu ul ul>li.has-sub:hover>a:before {
        top: 17px;
        height: 0
    }

    .custnav #cssmenu>ul {
        background-color: #da291c;
        background-repeat: no-repeat;
        background-position: 80% 90%;
        background-image: url(https://ifixscreens.com/wp-content/uploads/2021/05/navigationBg.jpg);
        background-size: cover !important;
        list-style-type: none;
        top: 0;
        left: 0;
        padding: 0;
        position: fixed !important;
        overflow-x: hidden;
        overflow-y: auto;
        height: 100% !important;
        width: 100%;
        margin: 0;
        opacity: 0;
        visibility: hidden;
        transition-duration: .5s;
        transition-timing-function: ease;
        transition-property: opacity;
        display: block !important;
        padding-top: 50px !important;
        z-index: 99
    }

    .custmenu.isactive #cssmenu>ul.open,
    .custmenu.isactive #cssmenu>.main-menu {
        opacity: 1;
        visibility: visible
    }

    #cssmenu::after,
    #cssmenu>ul::after {
        content: ".";
        display: block;
        clear: both;
        visibility: hidden;
        line-height: 0;
        height: 0
    }

    .custmenu.isactive #cssmenu>ul.open,
    .custmenu.isactive #cssmenu>.main-menu {
        opacity: 1;
        visibility: visible
    }

    #cssmenu,
    #cssmenu ul,
    #cssmenu ul li,
    #cssmenu ul li a,
    #cssmenu #menu-button {
        margin: 0;
        padding: 0;
        border: 0;
        list-style: none;
        line-height: 1;
        display: block;
        position: relative;
        box-sizing: border-box
    }

    .custmenu #cssmenu ul li {
        opacity: 0;
        width: 100%;
        overflow: hidden;
        transform: translateY(5px) scale(.75);
        transform-origin: top center;
        transition: transform .25s ease-in-out, opacity .25s ease-in-out
    }

    .custmenu.isactive #cssmenu ul li {
        transform: scale(1);
        opacity: 1
    }

    #cssmenu ul li a {
        text-align: center !important;
        background-color: transparent;
        color: #fff;
        display: block;
        position: relative;
        padding: 12px 15px;
        font-size: 17px;
        letter-spacing: 1px;
        text-decoration: none;
        color: #fff;
        font-weight: 600;
        text-transform: uppercase;
        text-decoration: none;
        transition-duration: .2s;
        transition-timing-function: ease-in-out;
        transition-property: color, background-color;
        font-family: 'Montserrat', sans-serif !important;
    }

    #cssmenu ul li {
        width: 100%
    }

    #cssmenu ul ul li {
        width: 100% !important;
        padding: 0
    }

    #cssmenu ul ul li a {
        color: #fff;
        background: 0;
        font-size: 14px;
        letter-spacing: 0;
        width: 100%;
        padding: 11px 15px !important
    }

    #cssmenu ul ul li a:hover,
    #cssmenu ul ul li a:focus {
        background: #fff !important;
        color: var(--red) !important
    }

    #cssmenu ul ul {
        background: var(--red);
        display: none
    }

    #cssmenu ul .sub-menu.open {
        display: inline-block
    }
}

@media only screen and (min-width: 601px) and (max-width: 767px) {
    .ifsc_footer .custftrSec1 {
        padding: 30px 0 10px !important;
    }

    .ifsc_footer .custftrSec1>.wrapper {
        display: inline-block !important;
    }

    .ifsc_footer .custftrSec1>.wrapper>.ftrCol {
        margin: 0 0 30px 0 !important;
    }

    .ifsc_footer .custftrSec1>.wrapper>.ftrCol {
        margin: 0 2% 30px 0 !important;
        width: 48% !important;
    }

    #ifs_footer1 {
        width: 100% !important;
    }

    #subscribeForm {
        width: 100%;
    }

    .ifsc_footer .custftrSec1>.wrapper>.ftrCol {
        margin: 0 0 30px 0 !important;
    }

    .ifsc_footer .custftrSec1>.wrapper>.ftrCol {
        margin: 0 2% 30px 0 !important;
        width: 48% !important;
    }

    #ifs_footer2,
    #ifs_footer3 {
        width: 50% !important;
        display: inline-block;
        margin: 0 !important;
        padding: 0 10px 0 0;
    }

    .ifsc_footer .custftrSec1>.wrapper>.ftrCol {
        margin: 0 0 30px 0 !important;
    }

    .ifsc_footer .custftrSec1>.wrapper>.ftrCol {
        margin: 0 2% 30px 0 !important;
        width: 48% !important;
    }

    #ifs_footer4 {
        width: 100% !important;
    }

    .custftrBtmLft {
        width: 100%;
    }

    .custftrBtmRt {
        text-align: center;
        width: 100%;
    }

    .custftrBtmRt ul {
        width: 100%;
    }

    .custftrBtmRt ul li {
        margin: 0 10px !important;
    }
}

@media only screen and (min-width: 100px) and (max-width: 600px) {
    .ifsc_footer .custftrSec1 {
        padding: 30px 0 10px !important;
    }

    .ifsc_footer .custftrSec1>.wrapper {
        display: inline-block !important;
    }

    .ifsc_footer .custftrSec1>.wrapper>.ftrCol {
        margin: 0 0 30px 0 !important;
    }

    .ifsc_footer .ttl {
        font-size: 16px !important;
    }

    #subscribeForm {
        width: 100%;
    }

    .ifsc_footer .custftrSec1>.wrapper>.ftrCol {
        margin: 0 0 30px 0 !important;
    }

    #ifs_footer2,
    #ifs_footer3 {
        width: 50% !important;
        display: inline-block;
        margin: 0 !important;
    }

    .ifsc_footer .ttl {
        font-size: 16px !important;
    }

    .ifsc_footer .ttl {
        font-size: 16px !important;
    }

    .ifsc_footer .custftrSec1>.wrapper>.ftrCol {
        margin: 0 0 30px 0 !important;
    }

    .custftrBtmLft {
        width: 100%;
    }

    .custftrBtmRt {
        text-align: center;
        width: 100%;
    }

    .custftrBtmRt ul {
        width: 100%;
    }

    .custftrBtmRt ul li {
        margin: 0 10px !important;
    }
}

@media only screen and (min-width: 100px) and (max-width: 767px) {
    .ifshdr .wrapper {
        padding: 0 5px !important;
    }
}

@media only screen and (min-width: 100px) and (max-width: 600px) {
    .ifshdrTopmain ul {
        text-align: center;
    }

    .ifshdrTopmain ul li {
        margin: 0 !important;
    }

    .ifshdrTopmain ul li a {
        padding: 5px 5px !important;
    }

    .ifshdr {
        padding: 10px 0 2px;
    }

    .ifs_page_header .ifshdrmain {
        padding: 0;
    }

    .ifsHdrRt {
        width: 20%;
        float: left;
    }

    .custhdr {
        display: block;
    }

    #cssmenu #menu-button {
        background: #3d66ab;
        float: left;
    }

    #cssmenu ul ul {
        background: none !important;
    }

    .ifsMenu {
        display: none;
    }

    .ifslogo {
        width: 60%;
        text-align: center;
    }

    .ifslogo a {
        padding: 0 !important;
    }

    .ifslogo a {
        margin: 0;
    }

    .ifslogo a img,
    .ifslogo a svg {
        width: 190px;
    }

    .ifslogo a img,
    .ifslogo a svg {
        bottom: 0;
    }

    .ifs_page_header .ifslogo img {
        width: 190px;
    }

    .ifs_page_header .ifslogo img {
        float: left;
    }

    .custIfsloaction {
        display: inline-block;
    }
}

@media (max-width:1440px) {
    .devices_grid_item {
        width: calc(25% - 90px/4);
    }

    .issues_grid_item {
        width: calc(25% - 60px/4);
    }
}

@media (max-width:1280px) {
    .devices_grid_item {
        width: calc(33.3% - 60px/3);
    }

    .issues_grid_item {
        width: calc(33.3% - 40px/3);
    }

    .time_list_item {
        width: calc(33.3% - 20px/3);
    }
}

@media (max-width:1024px) {
    .devices_grid {
        gap: 10px;
        flex-wrap: wrap;
    }

    .steps_list_item_hdr .h1 {
        font-size: 30px;
        line-height: 35px;
    }

    .steps_list_item_hdr .h3 {
        font-size: 18px;
        line-height: 24px;
    }

    .steps_list_item_hdr .text {
        font-size: 14px;
        line-height: 20px;
    }

    .devices_grid_item {
        width: calc(50% - 10px/2);
        display: flex;
        padding: 20px;
        justify-content: space-between;
        flex-direction: row-reverse;
    }

    .devices_grid_item .image_wrap {
        width: 40px;
        height: 40px;
        margin: 0;
    }

    .devices_grid_item .image_wrap svg,
    .devices_grid_item .image_wrap img {
        width: 100%;
        height: 100%;
    }

    .devices_grid_item .text {
        margin: 0px 0;
        font-size: 18px;
    }

    .steps_list_item_row {
        flex-wrap: wrap;
    }

    .steps_list_item_row .fixed_side_half {
        order: -1;
        width: 100%;
        padding: 20px;
        border-left: none;
        border-bottom: 1px solid rgba(0, 0, 0, .1);
        border-top: 1px solid rgba(0, 0, 0, .1);
        padding-top: 40px !important;
    }

    .about_our_repairs_cards {
        gap: 20px 30px;
    }

    .about_our_repairs_card {
        width: calc(50% - 30px/2);
    }

    .action_button_list .action_button {
        width: calc(50% - 10px/2);
    }

    .issues_grid {
        gap: 20px;
        margin-bottom: 20px;
    }

    .issues_grid_item {
        width: calc(50% - 20px/2);
        display: flex;
        padding-left: 30px;
        padding-right: 30px;
        flex-direction: row-reverse;
        outline: 5px solid #ededed;
        box-shadow: 0 0 10px 1px rgba(0, 0, 0, .1);
        position: relative;
    }

    .issues_grid_item .image_wrap {
        min-width: 70px;
        max-width: 70px;
        height: 70px;
        outline: none;
        box-shadow: none;
        position: unset;
    }

    .issues_grid_item .text {
        text-align: left;
    }

    .optional_issues {
        margin-bottom: 10px;
    }

    .near_stores_list_item {
        padding-right: 35px;
    }

    .convenience_radio_list_item {
        width: calc(50% - 5px/2);
        min-height: 60px;
    }

    .near_stores_list {
        margin-bottom: 20px;
    }

    .dates_list_item {
        width: calc(25% - 15px/4);
    }

    .time_list_item {
        width: calc(50% - 10px/2);
    }
}

@media (max-width:767px) {

    .devices_grid {
        gap: 10px;
    }

    .steps_list_item_hdr .h1 {
        font-size: 30px;
        line-height: 35px;
    }

    .steps_list_item_hdr .h3 {
        font-size: 18px;
        line-height: 24px;
    }

    .steps_list_item_hdr .text {
        font-size: 14px;
        line-height: 20px;
    }

    .devices_grid_item {
        width: 100%;
        display: flex;
        padding: 20px;
        justify-content: space-between;
        flex-direction: row-reverse;
    }

    .devices_grid_item .image_wrap {
        width: 40px;
        height: 40px;
        margin: 0;
    }

    .devices_grid_item .image_wrap svg,
    .devices_grid_item .image_wrap img {
        width: 100%;
        height: 100%;
    }

    .devices_grid_item .text {
        margin: 0px 0;
        font-size: 18px;
    }

    /* .about_our_repairs_card {
        width: 100%;
    } */

    .action_button_list .action_button {
        width: 100%;
    }

    .contact_form .input_row {
        flex-wrap: wrap;
    }

    .dates_list_item {
        width: calc(33.3% - 10px/3);
    }

    .time_list_item {
        width: calc(50% - 10px/2);
    }

    .convenience_radio_list_item {
        width: 100%;
        min-height: 50px;
    }
}

.step_brand_wrap select {
    font-family: 'Montserrat';
    outline: none;
    background-color: transparent;
    border: none;
    padding-left: 40px;
    position: absolute;
    top: 0;
    left: 0;
    width: 98%;
    height: 100%;
    padding-right: 15px;
    font-size: 15px;
}

.step_brand_wrap {
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 50px;
    padding: 10px;
    position: relative;
    display: flex;
    margin-bottom: 20px;
    gap: 10px;
    min-height: 50px;
}

.stores_wrap {
    margin-bottom: 20px;
    display: none;
}

.stores_wrap.active {
    display: block;
}

.contact_form .checkbox_list {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px 20px;
}

.contact_form .checkbox_list h4.heading {
    width: 100%;
    font-weight: 500;
}

.contact_form .checkbox_list h4.heading {
    width: 100%;
    font-weight: 500;
}

span.error {
    color: var(--red);
    font-size: 12px;
    font-family: 'Montserrat';
}

.contact_form .checkbox_list span.error {
    display: block;
    width: 100%;
    margin-bottom: 10px;
}

.succes_banner {
    display: flex;
    flex-direction: column;
}

ul.apptul {
    z-index: 100;
    position: absolute;

    top: 47px;
    left: 0;
    background: rgba(255, 255, 255, .5);
    border-radius: 10px;
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 0.5);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    list-style: none;
    padding: 0;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
}

.action_button {
    cursor: pointer;
    position: relative;
}

main#main {
    padding: 0;
}

ul.apptul a {
    color: rgb(40, 48, 61);
    font-family: 'Montserrat';
    font-weight: 500 !important;
    text-decoration: none;
    padding: 8px 20px;
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 14px;
    line-height: 19px;
    text-decoration: none;
}

ul.apptul a:hover {
    background: #fff;
    scale: 1.02;
}

ul.apptul li:first-child a {
    border-radius: 10px 10px 0 0;
}

ul.apptul li:last-child a {
    border-radius: 0 0 10px 10px;
}

.action_button_list .action_button:hover ul.apptul {
    opacity: 1;
    pointer-events: all;
}

.cancel-button {
    outline: none !important;
    white-space: nowrap;
    /* width: calc(33.3% - 20px/3); */
    border: 2px solid var(--red);
    height: 50px;
    font-weight: 600;
    color: var(--red) !important;
    font-size: 14px;
    font-family: 'Montserrat';
    border-radius: 50px;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    text-decoration: none;
    background: transparent !important;
    margin-bottom: 10px;
    max-width: max-content;
    padding-left: 30px;
    padding-right: 30px;
    cursor: pointer;
}


@media (max-width: 1024px) {
    .step_navigation {
        position: fixed;
        bottom: 0;
        z-index: 100;
        background: #fff;
        width: 100%;
        left: 0;
        padding: 20px;
        box-shadow: 0 -5px 7px -4px rgba(0, 0, 0, 0.2);
        justify-content: space-between;
    }

    .steps_list_item_row .left_half {
        min-height: auto;
    }

    .steps_list_item_row {
        gap: 0;
        flex-wrap: wrap-reverse !important;
    }

    .summary_wrap .device_other_details {
        text-align: right;
    }

    .summary_wrap .device_selected>.text {
        text-align: left;
    }

    .summary_wrap .device_selected>.image_wrap {
        margin: 0 0 15px;
    }
}

@media (max-width: 767px) {
    .issues_grid_item {
        width: 100%;
        align-items: center;
    }

    .issues_grid_item .text {
        width: calc(100% - 70px);
    }
}

.optional_issues {
    margin-bottom: 30px;
}



.slotserr {
    background: #f002;
    border: 1px solid var(--red);
    padding: 10px 20px;
    border-radius: 6px;
}

.fixed_steps_list_item {
    counter-increment: example-counter 1;

}

.fixed_steps_list_item .circle::before {
    content: counter(example-counter);
    color: var(--blue);
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    font-weight: 700;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.fixed_steps_list_item.active .circle::before {
    color: var(--white);
}

.fixed_steps_list_item.activechecked .circle {
    background-color: var(--blue);
}

.fixed_steps_list_item.activechecked .circle::before {
    content: '\f00c';
    color: #fff;
    font-family: 'FontAwesome';
}

.ifshdrTopmain ul {
    display: flex;
    justify-content: flex-end;
}

@media (max-width:1100px) {
    .ifshdrTopmain ul {
        justify-content: center;
    }
}


.about_our_repairs_card .icon svg {
    width: 40px;
    height: 40px;
    padding: 0;
}

.about_our_repairs_card .icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.use_location_toggle {
    margin-bottom: 20px;
}

.issues_grid_item .text {
    font-size: 15px;
}

.optional_issues textarea {
    font-size: 14px;
}

.action_button_list .action_button .icon svg path {
    fill: var(--blue);
}

.action_button_list {
    justify-content: center;
}

.succes_banner .image_wrap {
    width: calc(80% - 80px);
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    height: 340px;
    /* width: 100%; */
    /* background: var(--blue); */
    /* padding: 20px; */
}

.box_ifsc h3::before {
    content: '1';
    padding-right: 10px;
}
.box_ifsc:nth-child(4) h3::before {
    content: '2';
}
.box_ifsc:nth-child(5) h3::before {
    content: '3';
}
.box_ifsc h3 {
    font-family: 'Montserrat';
    color: #000;
    font-weight: 600;
    font-size: 17px;
}

.box_ifsc p {
    font-family: 'Montserrat';
    color: #000;
    font-weight: 400;
    font-size: 14px;
}

.steps_list_item_hdr {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 50px;
    margin-bottom: 30px;
}

.first .steps_list_item_hdr {
    justify-content: center;
}

.box_ifsc {
    width: calc(33.3% - 100px/3);
}

.succes_banner {
    /* flex-direction: row; */
    /* justify-content: space-around; */
}

.action_button_list {
    /* flex-direction: column; */
    /* width: 40%; */
}

.action_button_list .action_button {
    /* width: 100%; */
}

.succes_banner .image_wrap img {
    /* width: 40%; */
}

.succes_banner .image_wrap img {
    -o-object-fit: contain;
    object-fit: contain;
}

@media (max-width:767px) {
    .succes_banner .image_wrap {
        width: auto;
        height: auto;
    }

    .box_ifsc {
        width: 100%;
    }
}

@media (max-width: 1024px) {
    .steps_list_item_row .fixed_side_half {
        min-width: auto;
    }
}



.ifssubmenu>li>a:hover>span {
    background: var(--red);
    color: #fff;
}

.ifssubmenu>li>a:hover .blue-fill {
    fill: var(--red);
}

.ifssubmenu>li>a:hover .red-fill {
    fill: var(--red);
}

.ifssubmenu>li>a:hover .black-fill {
    fill: var(--red);
}

.ifssubmenu>li>a:hover .path-fill-red {
    fill: var(--red);
}

.ifssubmenu>li>a:hover .path-fill-blue {
    fill: var(--red);
}

.ifssubmenu>li>a:hover .path-stroke-red {
    stroke: var(--red);
    fill: transparent;
}

.ifssubmenu>li>a:hover .path-stroke-blue {
    stroke: var(--red);
    fill: transparent;
}

.ifssubmenu>li>a:hover .path-1 {
    stroke: var(--red);
    fill: transparent;
}

.ifssubmenu>li>a:hover .path-2 {
    stroke: var(--red);
    fill: transparent;
}

.ifssubmenu>li>a:hover .path-3 {
    stroke: var(--red);
    fill: transparent;
}




.errorSec {
    background: #f002;
    border: 1px solid #f00;
    border-radius: 4px;
    padding: 7px;
    font-size: 14px;
    color: #f00;
    margin-top: 10px;
}

.errorSec ul {
    list-style: none;
    padding-left: 7px;
}


.ifs_page_header .ifslogo {
    margin-bottom: -40px;
}

.steps_list_item.first {
    padding-top: 30px;
}

@media (min-width:1200px) and (max-width:1600px) {
    .ifs_page_header .ifslogo img {
        width: 200px;
    }

    .ifs_page_header .ifsMenu {
        margin: 10px 0;
    }

    .ifs_page_header .ifslogo {
        margin-bottom: -30px;
    }

    .devices_grid_item {
        width: calc(20% - (20px*4)/5);
    }

    .devices_grid_item .image_wrap {
        width: 80px;
        height: 80px;
    }

    .devices_grid {
        gap: 20px;
    }

    .devices_grid_item .text {
        margin-bottom: 10px;
    }

    .steps_list_item.first {
        padding-top: 20px;
    }
}



* {
    transition: 0.1s;
}

.search_form .input_wrap {
    border-color: rgba(61, 102, 171, 1);
}

.step_brand_wrap {
    border-color: rgba(61, 102, 171, 1);
}

.optional_issues textarea {
    border-color: rgba(61, 102, 171, 0.5);
}

.near_stores_list_item {
    border-color: rgba(61, 102, 171, 0.5);
}

.convenience_radio_list_item * {
    pointer-events: none;
}

.convenience_radio_list_item::before {
    border-color: rgba(61, 102, 171, 1);
}

.near_stores_list_item::before {
    outline-color: rgba(61, 102, 171, 1);
}

.near_stores_list_item * {
    pointer-events: none;
}

.convenience_radio_list_item {
    border-color: rgba(61, 102, 171, 1);
    color: rgba(61, 102, 171, 1);
}

.convenience_radio_list_item.active::before {
    background-color: #fff;
}

.convenience_radio_list_item.active {
    background-color: rgba(61, 102, 171, 1);
    color: #fff;
}

@media (min-width:1025px) {
    .issues_grid_item .image_wrap {
        box-shadow: 0 0 10px 1px rgba(61, 102, 171, 0.2);
        outline: 5px solid rgba(61, 102, 171, 0.3);
    }
}

.near_stores_list_item.active {
    background: rgba(61, 102, 171, 1);
}

.near_stores_list_item.active::before {
    background: #fff;
}

.near_stores_list_item.active .store_name {
    color: #fff;
}

.near_stores_list_item.active .next_date_available,
.near_stores_list_item.active .store_address {
    color: #fff;
}

.near_stores_list_item.active .store_distance {
    color: #fff;
}

.dates_list_item {
    border-color: rgba(61, 102, 171, 0.5);
}

.dates_list_item.active,
.dates_list_item:hover {
    border-color: rgba(61, 102, 171, 1);
}

.custcheckoutLft>.grid-col-checkout,
.custcheckoutRt>.grid-col-checkout {
    border-color: rgba(61, 102, 171, 0.3);
}

.woocommerce form.checkout .express-one-page-checkout-main p.form-row input.input-text {
    border-color: rgba(61, 102, 171, 0.3) !important;
}

.woocommerce-checkout span.select2-selection.select2-selection--single {
    border-color: rgba(61, 102, 171, 0.3) !important;
}

.express-one-page-checkout-main textarea#order_comments {
    border-color: rgba(61, 102, 171, 0.3) !important;
}

.wc-stripe-elements-field,
.wc-stripe-iban-element-field {
    border-color: rgba(61, 102, 171, 0.3);
}

.express-one-page-checkout-main .cart_totals div {
    border-color: rgba(61, 102, 171, 0.5);
}

.custcheckoutRt>.grid-col-checkout .grid-col-checkout {
    border-color: rgba(61, 102, 171, 0.5);
    border-radius: 0;
}

#add_payment_method #payment ul.payment_methods,
.woocommerce-cart #payment ul.payment_methods,
.woocommerce-checkout #payment ul.payment_methods {
    border-color: rgba(61, 102, 171, 0.5);
    border-radius: 0;
}

.devices_grid_item {
    border-color: rgba(61, 102, 171, 0.4);
    box-shadow: 0 0 10px 1px rgba(61, 102, 171, 0.2);
}

/* @media (max-width:1440px) { */
.steps_list_item.first .steps_list_item_hdr .h3 {
    display: none;
}

.steps_list_item.first .steps_list_item_hdr .text {
    display: none;
}

/* } */
@media (max-width:1024px) {
    .steps_list_item_row .fixed_side_half {
        display: none;
    }

    .map_wrap {
        display: none;
    }
}

.issues_grid_item .image_wrap,
.issues_grid_item .text {
    pointer-events: none;
}

[data-variation-len="1"] .issues_grid_item {
    display: none;
}

h2.repOptTitle {
    font-size: 22px;
    line-height: 35px;
    color: #3e67ac;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    margin-bottom: 10px;
}

.visit_details_checks .checkbox_list_item label::before {
    content: '';
    width: 19px;
    height: 19px;
    position: absolute;
    top: 0;
    left: 0;
    background-size: contain;
    background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iSWNvbnMiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZGVmcz48L2RlZnM+PHBhdGggY2xhc3M9ImNscy0xIiBkPSJNMiwxMGMwLDguNDkxLDkuMTI2LDEzLjY1OCw5LjUxNCwxMy44NzRhMSwxLDAsMCwwLC45NzIsMEMxMi44NzQsMjMuNjU4LDIyLDE4LjQ5MSwyMiwxMEExMCwxMCwwLDAsMCwyLDEwWk0xMiwyYTguMDA5LDguMDA5LDAsMCwxLDgsOGMwLDYuMjc0LTYuMiwxMC42OC04LDExLjgzQzEwLjIsMjAuNjgsNCwxNi4yNzQsNCwxMEE4LjAwOSw4LjAwOSwwLDAsMSwxMiwyWiI+PC9wYXRoPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTEyLDE1YTUsNSwwLDEsMC01LTVBNS4wMDYsNS4wMDYsMCwwLDAsMTIsMTVabTAtOGEzLDMsMCwxLDEtMywzQTMsMywwLDAsMSwxMiw3WiI+PC9wYXRoPjwvc3ZnPg==);
}

.visit_details_checks .checkbox_list_item.date label::before {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTIgMmM1LjUxNCAwIDEwIDQuNDg2IDEwIDEwcy00LjQ4NiAxMC0xMCAxMC0xMC00LjQ4Ni0xMC0xMCA0LjQ4Ni0xMCAxMC0xMHptMC0yYy02LjYyNyAwLTEyIDUuMzczLTEyIDEyczUuMzczIDEyIDEyIDEyIDEyLTUuMzczIDEyLTEyLTUuMzczLTEyLTEyLTEyem0xIDEydi02aC0ydjhoN3YtMmgtNXoiLz48L3N2Zz4=);
}

.visit_details_checks .checkbox_list_item.contact label::before {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yNCAyMGgtM3Y0bC01LjMzMy00aC03LjY2N3YtNGgydjJoNi4zMzNsMi42NjcgMnYtMmgzdi04LjAwMWgtMnYtMmg0djEyLjAwMXptLTE1LjY2Ny02bC01LjMzMyA0di00aC0zdi0xNC4wMDFsMTggLjAwMXYxNGgtOS42Njd6bS02LjMzMy0yaDN2MmwyLjY2Ny0yaDguMzMzdi0xMGwtMTQtLjAwMXYxMC4wMDF6Ii8+PC9zdmc+);
}

.visit_details_checks .checkbox_list_item label {
    display: flex;
    justify-content: space-between;
}

.visit_details_checks .checkbox_list_item .change_location {
    cursor: pointer;
    font-size: 12px;
    align-self: center;
    text-decoration: underline;
}

.visit_details_checks .checkbox_list_item .store_location_text {
    padding-left: 30px;
    margin-top: 7px;
    padding-right: 60px;

}

.visit_details_checks .checkbox_list_item .store_location_links {
    cursor: pointer;
    padding-left: 30px;
    margin-top: 7px;
    font-size: 12px;
    color: #333;
    font-family: 'Montserrat';
    font-weight: 600;
    display: flex;
    gap: 5px 10px;
    flex-wrap: wrap;
    text-decoration: underline;
    padding-right: 60px;

}

.visit_details_checks .checkbox_list_item .store_location_link {
    color: inherit;
}

.contact_form .checkbox_list_item label::before {
    content: '';
    width: 19px;
    height: 19px;
    content: '';
    position: absolute;
    top: -2px;
    left: 0;
    border: 1px solid rgba(0, 0, 0, .5);
    border-radius: 4px;
}

.visit_details_checks .checkbox_list_item .store_location_text p {
    font-size: 15px;
}

.visit_details_checks .checkbox_list_item .store_location_text p strong {
    font-weight: 500;
}

.checkbox_list.visit_details_checks {
    gap: 20px;
}

.store-hours-popup {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    top: 0;
    left: 0;
    pointer-events: none;
    opacity: 0;
    z-index: 10001;
    align-items: center;
    justify-content: center;
}

.store-hours-popup.active {
    opacity: 1;
    pointer-events: all;
}

.store-hours-popup::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .4);
    top: 0;
    left: 0;
}

.store-hours-popup .inner {
    position: relative;
    z-index: 1;
    background: #fff;
    max-width: 500px;
    border-radius: 20px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, .1);
    padding: 30px;
}

.store-hours-popup-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.store-hours-popup-header h3 {
    color: #3e67ac;
    font-family: 'Montserrat';
    font-weight: 600;
    margin-bottom: 0px;
}

.store-hours-popup-header .close {
    cursor: pointer;
    width: 30px;
    height: 30px;
    background: #fff;
    border: 1px solid #000;
    border-radius: 50%;
    padding: 5px;
}

.store-hours-popup .hours-list ul {
    list-style: none;
}

.store-hours-popup .hours-list ul li {
    display: flex;
    justify-content: space-between;
}

.store-hours-popup .hours-list ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.store-hours-popup .hours-list ul li span.day {
    font-family: 'Montserrat';
    font-weight: 600;
    color: #333;
}

.store-hours-popup .close-bottom a {
    outline: 1px solid var(--blue);
    border-radius: 50px;
    color: #fff;
    font-family: 'Montserrat';
    font-weight: normal;
    display: inline-flex;
    cursor: pointer;
    padding: 10px 30px;
    cursor: pointer;
    background: var(--blue);
}

.store-hours-popup .close-bottom {
    padding: 20px 0;
    display: flex;
    justify-content: flex-end;
}

.need_help_text {
    text-align: center;
    padding: 0 0 20px;
    font-family: 'Montserrat';
    color: #000;
    font-weight: 600;
    font-size: 17px;
}




.loader-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    z-index: 10000;
    opacity: 0;
    top: 0;
    left: 0;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .loader {
    width: 20px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: var(--blue);
    box-shadow: 0 0 0 0 var(--blue);
    animation: l2 1.5s infinite linear;
    position: relative;
  }
  
  .loader:before,
  .loader:after {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 var(--blue);
    animation: inherit;
    animation-delay: -0.5s;
  }
  
  .loader:after {
    animation-delay: -1s;
  }
  
  @keyframes l2 {
    100% {
      box-shadow: 0 0 0 40px #0000
    }
  }
  
  .loader-wrapper.active {
    pointer-events: all;
    opacity: 1;
  }























/* terms and privacy  */
.terms-tabs-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 1px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.terms-tabs-links h3 {
    width: 100%;
    font-weight: 700;
    text-align: center;
    font-size: 28px;
    line-height: 39px;
    font-family: 'Montserrat';
    padding-bottom: 10px;
}

.terms-tabs-links ul {
    display: flex;
    gap: 20px;
    list-style: none;
    padding-left: 0;
    justify-content: center;
    flex-wrap: wrap;
}

.terms-tabs-links ul li {
    margin: 0; 
}
.terms-tabs-links ul li a {
    color: #000;
    font-size: 15px;
    line-height: 26px;
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
}

.terms-tabs-links ul li a:hover {
    color: var(--red);
}

.repair-terms-of-service .inner {
    display: flex;
    gap: 40px;
    align-items: center;
}

.repair-terms-of-service .img-half {
    max-width: 37.6%;
}

.repair-terms-of-service .img-half img {
    width: 100%;
}
.repair-terms-of-service .content-half {
    max-width: 62.3988%;
}

.repair-terms-of-service .content-half h2 {
    font-size: 56px;
    line-height: 70px;
    font-weight: 700;
    font-family: 'Montserrat';
    color: var(--blue);
    margin-bottom: 10px;
}

.repair-terms-of-service .content-half h3 {
    font-size: 28px;
    line-height: 39px;
    font-weight: 600;
    font-family: 'Montserrat';
    color: var(--black);
}

.repair-terms-of-service .inner {
    padding: 15px;
}

.repair-terms-of-service {
    padding: 40px 0;
}

.repair-terms-of-service .content-half p {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    font-family: 'Montserrat';
    color: var(--black);
    padding: 5px 0 10px;
}

.repair-terms-of-service .content-half p strong {
    font-weight: 800;
}

.repair-terms-and-conditions2 {
    padding: 40px 0;
    background-image: linear-gradient(#f2f2f2, #f2f2f2);
}

.repair-terms-and-conditions2 .inner {
    padding: 15px;
    margin-bottom: 10px;
}

.repair-terms-and-conditions2 h3 {
    text-align: center;
    font-size: 28px;
    line-height: 39px;
    color: #0a0a0a;
    font-weight: 700;
    font-family: 'Montserrat';
}

.repair-terms-and-conditions2 p {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    font-family: 'Montserrat';
    color: var(--black);
    padding: 5px 0 10px;
}

.repair-terms-and-conditions2 ul li {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    font-family: 'Montserrat';
    color: var(--black);
    padding: 5px 0 10px;
}
.scroll-to-top-btn-wrap {
    margin: 20px 0;
}
.scroll-to-top-btn {
    background: var(--blue);
    font-family: 'Montserrat', sans-serif;
    padding: 26px 60px;
    border-radius: 50px;
    font-weight: 600;
    border: none;
    font-size: 17px;
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    line-height: 1.2em;
    display: inline-flex;
}
.scroll-to-top-btn:hover {
    text-decoration: none !important;
    background-color: var(--red);
}
.repair-terms-and-conditions2-red {
    background: var(--red);
    color: #fff;
}
.repair-terms-and-conditions2 h2.align-left {
    text-align: left;
}
.repair-terms-and-conditions2 h2,
.repair-terms-and-conditions2-red h2 {
    text-align: center;
    font-size: 56px;
    line-height: 70px;
    font-weight: 700;
    color: inherit;
}
.repair-terms-and-conditions2 h2 {
    color: var(--blue);
}
.repair-terms-and-conditions2-red h2 {
    color: var(--red);
}
.repair-terms-and-conditions2-red h3 {
    color: #fff;
}
.repair-terms-and-conditions2-red p {
    color: #fff;
}
.terms-popupoverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
}
.terms-popupmain.active {
    pointer-events: all;
    opacity: 1;
}
@keyframes showpopupterms {
    0% { 
        opacity: 0;
        scale: 0.9;
    }
    100%{
        opacity: 1;
        scale: 1;
    }
}
.terms-popupmain {
    z-index: 100000;
    opacity: 0;
    pointer-events: none;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.terms-popupmain.active .terms-popupinner {
    animation: showpopupterms .5s;
    -webkit-animation: showpopupterms .5s;
}
.terms-popupinner .scrollinner {
    overflow-y: auto;
    max-height: 600px;
    height: calc(100% - 30px);
}
.terms-popupinner {
    border: 1px solid #f2f2f2;
    background-color: #fff;
    position: relative;
    z-index: 1;
}
.terms-popupmain .close {
    cursor: pointer; 
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    padding: 10px;
    background-color: #fff;
    border-radius: 50%;
    top: -10px;
    right: -10px;
    position: absolute;
    border: 1px solid rgba(0,0,0,0.5);
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
@media (max-width:1024px) {
    .repair-terms-of-service .inner {
        flex-wrap: wrap;
    }
    .repair-terms-of-service .img-half {
        width: 100%;
        max-width: 100%;
    }
    .repair-terms-of-service .img-half img {
        max-width: 300px;
        margin: auto;
        display: flex;
    }
    .repair-terms-of-service .content-half {
        width: 100%;
        max-width: 100%;
    }
    .repair-terms-of-service .content-half h2 {
        font-size: 30px;
        line-height: 1.2;
        text-align: center;
    }
    .repair-terms-of-service .content-half h3 {
        font-size: 20px;
        line-height: 1.2;
        text-align: center;
    }
    .repair-terms-of-service .content-half p {
        font-size: 15px;
        line-height: 1.4;
        text-align: center;
    }
    .repair-terms-and-conditions2 h2 {
        font-size: 30px;
        line-height: 1.2;
    }
    
    .repair-terms-and-conditions2 h3 {
        font-size: 20px;
        line-height: 1.2;
    }
    .repair-terms-and-conditions2 p {
        font-size: 15px;
        line-height: 1.4;
    }
    .scroll-to-top-btn {
        padding: 12px 30px;
        font-size: 14px;
    }
}
.agree_row p a {
    cursor: pointer;
    text-decoration: underline;
}

.mailinContIns {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.mailinContIns b {
    font-size: 22px;
    line-height: 35px;
    color: #3e67ac;
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    margin: 0 0 10px 0;
}
.mailinContIns li {
    color: #333;
    font-family: 'Montserrat';
    /* font-weight: 600; */
    font-size: 14px;
    margin-bottom: 10px;
}

ul.mailinContIns {
   /*  padding-left: 20px; */
}
.mailinContInsfirst {
    margin-top: 30px;
}
.box_ifscss {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 20px;
}
.box_ifscss .box_ifsc {
    width: 100%;
    border: 1px solid #0002;
    padding: 20px;
    border-radius: 10px;
}
@media (min-width:768px) {
    .box_ifscss .box_ifsc {
        width: calc(50% - ((20px*1)/2));
    }
}
.second .steps_list_item_hdr .h3 .nextTetx {
    font-size: 18px;
    line-height: 1.2;
   /*  font-weight: 600; */
    width: 100%;
    display: block;
    margin-top: 10px;
}
ul.mailinContInsnomar {
    margin-top: -15px;
    margin-bottom: 0;
}
ul.mailinContIns li {
    position: relative;
    list-style: none;
    padding-left: 30px;
}
ul.mailinContIns li:before {
    border: solid #333;
    border-width: 0 2px 2px 0;
    content: "";
    height: 13px;
    left: 10px;
    position: absolute;
    top: 13px;
    -webkit-transform: rotate(45deg) translateY(-50%) translateX(-12px);
    transform: rotate(45deg) translateY(-50%) translateX(-12px);
    width: 5px;
}




.nextTetx22 {
    font-size: 18px;
    line-height: 1.2;
    /* font-weight: 600; */
    width: 100%;
    color: #3e67ac;
    font-family: 'Montserrat', sans-serif;
    display: block;
    margin-top: 10px;
}

.nextTetx22 a {
    color: inherit;
}